import htLogoWhite from './images/htLogoWhite.png';
import hindustanLogo from './images/Hindustan.png';
import liveHindu from './images/liveHindustan.png';
import mint from './images/mint.png';
import airtelLogo from './images/PaymentOffer/airtel.png'
import mobikwikLogo from './images/PaymentOffer/mobikwik.png'
import defaultLogo from './images/PaymentOffer/payment.png'
import paytmLogo from './images/PaymentOffer/paytm.png'
import razorpayLogo from './images/PaymentOffer/razorpay.png'
import iciciLogo from './images/PaymentOffer/icici.png'
import hdfcLogo from './images/PaymentOffer/hdfc.png'


export const imagesMapped = [{
    image: airtelLogo,
    name: "Airtel",
    classNameCSS: "airtel",
    fullForm: "Airtel Payment",
    imageBasis: "print"
 },
 {
   image: razorpayLogo,
   name: "Razorpay",
   classNameCSS: "razorpay",
   fullForm: "Razorpay Payment",
   imageBasis: "print"
 },
 {
   image: mobikwikLogo,
   name: "Mobikwik",
   classNameCSS: "mobikwik",
   fullForm: "Mobikwik Payment",
   imageBasis: "print"
 },
 {
   image: defaultLogo,
   name: "Default",
   classNameCSS: "Default",
   fullForm: "Default",
   imageBasis: "print"
 },
 {
   image: paytmLogo,
   name: "Paytm",
   classNameCSS: "paytm",
   fullForm: "Paytm Payment",
   imageBasis: "print"
 },
 {
  image: iciciLogo,
  name: "ICICI",
  classNameCSS: "icici",
  fullForm: "ICICI Payment",
  imageBasis: "print"
},
{
  image: hdfcLogo,
  name: "HDFC",
  classNameCSS: "hdfc",
  fullForm: "HDFC Payment",
  imageBasis: "print"
},
{
  image: defaultLogo,
  name: "Default",
  classNameCSS: "Default",
  fullForm: "Default",
  imageBasis: "print"
},
 {
   image: htLogoWhite,
   name: "HT",
   classNameCSS: "ht",
   fullForm: "Hindustan Times",
   imageBasis: "print"
 },
 {
    image: hindustanLogo,
    name: "HH",
    classNameCSS: "hh",
    fullForm: "Hindustan",
    imageBasis: "print"
 },
 {
    image: mint,
    name: "Mint",
    classNameCSS: "mint",
    fullForm: "Mint",
    imageBasis: "print"
 },
 {
   image: mint,
   name: "BP",
   classNameCSS: "BP",
   fullForm: "Mint",
   imageBasis: "print"
},
 {
    image:liveHindu,
    name: "LH",
    classNameCSS:"LH",
    fullForm: "Live Hindustan" ,
    imageBasis: "digital"
 },
 {
    image:htLogoWhite,
    name: "HD",
    classNameCSS:"hd",
    fullForm: "Hindustan Times epaper",
    imageBasis: "digital"
 },
 { 
   image:mint,
   name: "LM",
   classNameCSS:"lm",
   fullForm: "Mint android & ios app and epaper",
   imageBasis: "digital"
 }
 ]