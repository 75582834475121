import axios from 'axios';

const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }

})



class AuthService {
    async getAuth(username, password) {

        return await  authAxios.post("/apis/subscription/v1/auth", {
            username,
            password
        }).then(response => {
              console.log("Auth Response", response)
            if (response.data.token) 
            {
                sessionStorage.setItem("AuthInfo", response.data.token);
            }
            return response.data;
        });
    }



}

export default new AuthService();