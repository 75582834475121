import React from 'react'
import NavLinks from "./NavLinks";
import  './component.css'
 
import {FiMenu} from 'react-icons/fi'
import {GrFormClose} from 'react-icons/gr';
import {useState} from 'react'


const MobileNavigation =()=>
{
    const [open, setOpen] = useState(false)

    const hamburgerIcon = <FiMenu className="Hamburger" size ='40px' color='black'onClick={()=>setOpen(!open)}/>
    const closeIcon =<GrFormClose className="Hamburger" size ='40px' color='black'onClick={()=>setOpen(!open)}/>

    const cloaseMobile = () => setOpen(false);

    function disableScrolling()
    {
        var x=window.scrollX;
        var y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};
    }


    return(
        <div>
             <nav className="MobileNavigation">
                {open ? closeIcon :hamburgerIcon}
                 {open && <NavLinks className="stop-scroll" isMobile={true} cloaseMobile={cloaseMobile} />

                
                 }
            </nav>
        </div>
    );
}

export default MobileNavigation;