import React from 'react'
import NavLinks from "./NavLinks";
import Bell from "./../images/bell.png" 
import  './component.css'



const Navigation=()=>
{
    return(
        <nav className="Navigation NavBarNaviation">
            <NavLinks/>
            <li>
                <a>
                <img src={Bell} size ='40px'/></a>
            </li>
        </nav>
    );
}

export default Navigation;