import React from 'react'; 
import {motion} from 'framer-motion'
import './../css/Acquisition.css';

  
const NavLinks=(props)=>
{
    const animateFrom ={opacity:0, y: -40}
    const animateTo ={opacity:1, y: 0}

    function redirectToHome() 
    {
         window.location.href = '/';
    }

    function moengageLogoutBtn_Clicked()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.track_event( "Logout Button Clicked", 
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
         }
        )
      }
      window.Moengage.destroy_session();
    }
  
    function logout() 
    {
      //  debugger
       moengageLogoutBtn_Clicked();
       window.location.href = '/';
       sessionStorage.removeItem('orderDetails');
       sessionStorage.removeItem('selectedSchemes');
       sessionStorage.removeItem('selectedCouponsDue');
       sessionStorage.removeItem('journey');
       localStorage.removeItem('AuthInfo');
       sessionStorage.removeItem('stg_utm_source');
       sessionStorage.removeItem('stg_utm_medium');
       sessionStorage.removeItem('stg_utm_name');
       sessionStorage.removeItem('stg_mobile_number');
       sessionStorage.removeItem('stg_status');
       sessionStorage.removeItem('stg_clicked_OrderId');
       sessionStorage.removeItem('stg_renew_clicked_order_status');
       sessionStorage.removeItem('stg_renew_clicked_order_id');
       sessionStorage.removeItem('stg_selectedPlan_Name');
       sessionStorage.removeItem('stg_selectedPlan_Duration');
       sessionStorage.removeItem('stg_selectedPlan_Price');
       sessionStorage.removeItem('stg_renew_clicked_order_id');
       sessionStorage.removeItem('stg_emailAdress_Checkout_updated');
       sessionStorage.removeItem('stg_existing_address_DeliverPage_updated');
       sessionStorage.removeItem('stg_new_address_DeliverPage_updated');
       sessionStorage.removeItem('stg_mo_redirectFrom');
       sessionStorage.removeItem("stg_mo_OTP_Status");
       sessionStorage.removeItem("stg_acq_exitingUser")
       sessionStorage.removeItem("stg_acq_freshUser")
       sessionStorage.removeItem("acqPhoneNumber")
       sessionStorage.removeItem("createNewAccountActive")
       sessionStorage.removeItem("mo_acq_numberStatus")
       sessionStorage.removeItem("stg_acq_otp")
       sessionStorage.removeItem("stg_acq_userType")
       sessionStorage.clear();
       // Clear cookies
  document.cookie.split(";").forEach(function(c) {
    document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
     }

     
     function redeemCoupon()
     {
        window.location.href = "/redeem";   
     }

     function vendorChange()
     {
        window.location.href = "/vendorChange";   
     }

  return (
          <ul > 
                            <motion.li 
                                    initial={animateFrom}
                                    animate={animateTo}
                                    transition={{delay:0.05}}
                                    onClick={() => props.isMobile && props.cloaseMobile()}>
                                    <a  onClick={redirectToHome}>Home</a>
                                </motion.li>
                                <motion.li 
                                  initial={animateFrom}
                                  animate={animateTo}
                                  transition={{delay:0.05}}
                                  onClick={() => props.isMobile && props.cloaseMobile()}>
                                    <a  onClick={redeemCoupon}>Digital Coupon</a>
                                </motion.li>
                                <motion.li 
                                  initial={animateFrom}
                                  animate={animateTo}
                                  transition={{delay:0.05}}
                                  onClick={() => props.isMobile && props.cloaseMobile()}>
                                     <a onClick={vendorChange}>Change Vendor</a>
                                </motion.li>
                                 {/*<motion.li 
                                  initial={animateFrom}
                                  animate={animateTo}
                                  transition={{delay:0.05}}
                                  onClick={() => props.isMobile && props.cloaseMobile()}>
                                    <a>Profile</a>
                                </motion.li>

                                <motion.li 
                                  initial={animateFrom}
                                  animate={animateTo}
                                  transition={{delay:0.05}}
                                  onClick={() => props.isMobile && props.cloaseMobile()}>
                                    <a>Contact Us</a>
                                </motion.li> */}
                                
                                <motion.li   
                                    initial={animateFrom}
                                    animate={animateTo}
                                    transition={{delay:0.05}}
                                    onClick={() => props.isMobile && props.cloaseMobile()}>
                                    <a  onClick={logout}>Logout</a>
                                </motion.li>
                            </ul>                 
  )
}
  
export default NavLinks;