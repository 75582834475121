import React, { Component } from 'react';
import UserService from '../../services/UserService';
import OtpPageNJ from '../OtpPageNJ';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/Style.css';
import '../../css/StyleV2.css';
import '../../css/animate.css';
import '../../css/Acquisition.css';
import flag from '../../images/india-flag.png';
import ReactGA from 'react-ga';

import publication from '../../images/publication.png';
import bottomNewsPaper from '../../images/bottomNewsPapger.png';
import {bookNowClick,nonLoginUserRedirection} from '../../common/commonFuntion'
import 'react-toastify/dist/ReactToastify.css';
import dotenv from 'dotenv';
import Home from '../HomeNJ';
import LoginHeader from  '../../subComponents/LoginHeader';
import { ToastContainer, toast } from 'react-toastify';
 
dotenv.config()

let refresh=0;


export default class Login extends Component 
{

  enterMobile = () => toast.warn("Mobile Number should not be more than 10 digits", {
    position: toast.POSITION.TOP_CENTER
 });

  constructor(props) {
    super(props);
    this.state = {
      value:'',
      phoneNumber: '',
      show: true,
      showMobileFirst: false,
      showMobile: true,
      loading: false,
      counter:30,
      delay:true,
      otpModel:false,
      getOtpBtn:true

    };
    this.handleChange = this.handleChange.bind(this);
    this.editHanlde=this.editHanlde.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.successToCheckout=this.successToCheckout.bind(this);
    this.useInterval=this.useInterval.bind(this);
  }

  moengageAqu_Registeration_Viewed()
  {
    
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      window.Moengage.track_event('Acquisition HTreaders Registration Viewed', 
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
        Mobile_Number : sessionStorage.getItem("acqPhoneNumber")
      })
    }
  }

  moengageAqu_GetOtp_Clicked()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on Get OTP on Acquisition login page")
      window.Moengage.update_unique_user_id("mo_htread_"+sessionStorage.getItem("acqPhoneNumber"));
      window.Moengage.track_event('Acquisition Get OTP Clicked', 
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
        Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   Mobile_Number_Status : sessionStorage.getItem("mo_acq_numberStatus"),
      })
    }
  }

  moengageAqu_editLink_Clicked()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on edit link on Acquisition login page")
      window.Moengage.track_event('Acquisition Edit Clicked', 
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
        Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   Mobile_Number_Status : sessionStorage.getItem("mo_acq_numberStatus"),
      })
    }
  }


  handleChange(e) 
  {
      this.setState({ phoneNumber: e.target.value.replace(/\D/g, '') });
      sessionStorage.setItem("acqPhoneNumber",e.target.value.replace(/\D/g, ''))
  }

  goBackPage() {
    this.setState({ phoneNumber: '' });
    this.setState({ showMobile: true })
    this.setState({ show: false })
  }

  successToCheckout() 
  {

    this.props.showCheckout()

  }

  editHanlde() 
  {
    this.setState({ show: true })
    this.setState({ showMobileFirst: false })
    this.setState({ phoneNumber: ""})
    sessionStorage.removeItem("stg_acq_exitingUser")
    sessionStorage.removeItem("stg_acq_freshUser")
    this.moengageAqu_editLink_Clicked()
  }

  componentDidMount()
  {
    ReactGA.ga('send','pageview','/newUserRegistration');

    if(sessionStorage.getItem("acqAccountNotAvailable_via_Login")==="ON")
    {
      this.setState({ phoneNumber: this.props.aquPhoneNumber})
      this.setState({ show: false })
      this.setState({ showMobileFirst: true })
      window.Moengage.update_unique_user_id("mo_htread_"+this.props.aquPhoneNumber);
      sessionStorage.setItem("acqAccountNotAvailable_via_Login","OFF")


    }
    else
    {
      sessionStorage.setItem("acqAccountNotAvailable_via_Login","OFF")
    }

    this.moengageAqu_Registeration_Viewed()

    if(refresh==='0')
    {
      window.location.reload(true);
    }
    refresh++;
  }


  useInterval()
  {
    clearInterval(this.Interval)
    this.setState({ counter: 30 })
    this.setState({ delay: true })
    this.Interval=setInterval(() => { 
      let timer=this.state.counter;
      if(timer > 0)
      {
        this.setState({
          counter: this.state.counter - 1
        })
      }
      else if(this.state.counter===0)
      {
        this.setState({ delay: false })
        clearInterval(this.Interval)
        
      }
    },1000)}

    getOtpEdit =() =>
    {
        this.setState({otpModel:true})
        this.setState({getOtpBtn:false})
        this.showotp();
        this.moengageAqu_GetOtp_Clicked();


    }

    getOtp =() =>
    {
      // this.setState({otpModel:true})
      // this.setState({getOtpBtn:false})
      // this.setState({showMobileFirst:true})
      // this.setState({show:false})
      this.showotp();
      this.moengageAqu_GetOtp_Clicked();

    }
  
  showotp = () => 
  {

    if(this.state.phoneNumber.length >10)
    {
        this.enterMobile();
        sessionStorage.setItem("mo_acq_numberStatus","Invalid Number")
    }

    else
    {
        this.setState({ loading: true });
        var source = "SSP";
        var fresh_user=true
        UserService.generateFreshUserOtp(this.state.phoneNumber,source,fresh_user).then(response => 
          {
        
            this.setState({ showMobile: true })
            this.setState({ loading: false });
            this.setState({getOtpBtn:false})
            this.setState({showMobileFirst:true})
            this.setState({show:false})
            this.setState({otpModel:true})
            sessionStorage.setItem("mo_acq_numberStatus","Vaild Number") 
            this.useInterval();
            ReactGA.ga('send','pageview','/newUserGetOtp');
          if (response.ok) { }
        }).catch(err => 
          {
            // window.location.href = '/newCustomer';
            // this.setState({ show: false });
            // this.setState({ showMobile: true });
            // this.setState({ loading: false });
        })
      } 
      // else 
      // {

      //   this.setState({ show: false });
      //   this.setState({ showMobile: true });
      //   this.setState({ loading: false });
      // }
    //}
  }

  enterPressed(event) 
  {
    var code = event.keyCode || event.which;
    
    if (code === 13 && this.state.phoneNumber.length>=10) 
    {
      this.showotp();
    }
  }

  logout() {

    this._logout()

  }

  _logout() {
    this.setState({
      auth: {
        ...this.state.auth,
        loggedIn: false,

      }
    })
  }


  render() {


    return (
        <div>
            <div class="row justify-content-center">
                <div class="formV2">
                    {this.state.show && 
                        <div>
                            <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center'>{this.props.title}</p>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img src={flag} className='flag fontNJ'/>&nbsp;+91</span>
                                </div>
                                    <input type="tel" class="form-control" value={this.state.phoneNumber} onChange={this.handleChange} onKeyPress={this.enterPressed} placeholder="Enter Number"/>
                            </div>
                            <div>
                                {this.state.phoneNumber.length<10? 
                                    (<button disabled={true} type="button" className="btn dislogin-btnV2 fontNJ">{this.props.btnName}</button>):
                                    (<button disabled={false} type="button" id ="otpBtn" className="btn login-btnV2 fontNJ" onClick={() => this.getOtp()}>{this.props.btnName}</button>)
                                }
                            </div>
                        </div>
                    }
                    {this.state.showMobileFirst && 
                        <div>
                            <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center'>{this.props.title}</p>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img src={flag} className='flag'/>&nbsp;+91</span>
                                </div>
                                <input type="text" class="form-control" value={this.state.value} readonly placeholder={this.state.phoneNumber}/>
                                <div class="input-group-append">
                                    <span class="input-group-text edit fontNJ resnedOtp" onClick={this.editHanlde}>Edit</span>
                                </div>
                            </div>
                            {this.state.getOtpBtn && 
                            <div className="regBtns">
                                 <button class="btn" id="otpBtn" onClick={() => this.getOtpEdit()} disabled="">Get OTP</button>
                            </div>
                            }
                            {this.state.otpModel &&
                                <OtpPageNJ otpCallBack={this.goBackPage} updateOtpTimer={this.useInterval} style={{ "height": "100%" }} mobile={this.state.phoneNumber} gotCheckout={this.successToCheckout} otpTimer={this.state.counter} timerDelay={this.state.delay} otpCallType={"fresh"} />
                            }
                            </div>
                    }
                    <ToastContainer />

                </div>
                    {/* <div class="d-flex justify-content-center mt-2">
                                <button class="clickV2 fontNJ" onClick={bookNowClick}>New Customer?</button> 
                    </div>
                    <img class="productImgV2 mt-4" src={bottomNewsPaper} width="50%"/>  
                    <img class="productImgV2 mt-4" src={publication} width="50%"/>     */}
            </div>
            {/* {this.state.showCheckout &&  
                <Home userInfo={sessionStorage.getItem("userInfo")} />
            } */}
      </div>
    );
  }
}
