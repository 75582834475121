import React, { PureComponent } from 'react'
import mainLogo from './../images/mainLogo.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './../css/Style.css';
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import  './component.css'

export default class LoggedInHeader extends PureComponent 
{

    constructor(props) {
        super(props);
        this.state = 
        {
           showHeader : sessionStorage.getItem("showHeader")
        }
     }

   render() 
   {
    if (this.state.showHeader==="OFF") 
    {
       return <div/> 
    }
    else
    {
     return (
        <div class="navigation-wrap bg-light start-header start-style">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                    <nav>
                        <div className="row">
                            <div className="col-mt-4">
                                <a class="navbar-brand" target="_blank"><img src={mainLogo} alt="main Logo"/></a>    
                            </div>
                            <div className="col-mt-8">
                                <div className="NavBar">
                                    <MobileNavigation/>
                                    <Navigation/>
                                </div>
                            </div>
                        </div>
                    </nav>      
                    </div>
                </div>
            </div>
        </div>
      )
     }
   }
}