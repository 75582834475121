import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Style.css';
import './../css/StyleV3.css';
import UserService from '../services/UserService';
import { schemeDescription, duplicateImageFilter } from '../services/schemeTextFilter';
import AllSchemes from './AllSchemes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../css/StyleV2.css';
import './../css/animate.css';
import LoggedInHeader from '../subComponents/LoggedInHeader';
import Moment from 'moment';
import PageLoader from '../subComponents/PageLoader';
import ReactGA from 'react-ga';
import VendorDetailsChange from '../components/VendorDetailsChange';

let button;
let redeemBtn;
let buttonHeader;
let duplicateImageRemoved;
var printText = [];
var digitalText = [];
let collapseLoop=0;
let couponType;
var publicationNameArr = {'HT':'Hindustan Times','HH': 'Hindustan Hindi','LM':'Live Mint','Mint':'Mint'}

export default class Home extends Component {
   notFound = () => toast.warn("Some netowrk issues, Please try again", {
      position: toast.POSITION.TOP_CENTER
   });
   emailUpdated = () => toast.success("Email has been updated", {
      position: toast.POSITION.TOP_CENTER
   });
   emailIdNotValid = () => toast.warn("Please enter valid email", {
      position: toast.POSITION.TOP_CENTER
   });
   networkIssue = () => toast.warn("Some netowork issue, Please try again!", {
      position: toast.POSITION.TOP_CENTER
   });
   enterEmail = () => toast.warn("Please enter email", {
      position: toast.POSITION.TOP_CENTER
   });
   constructor(props) {
      super(props);
      this.state = {
         userInfo: JSON.parse(sessionStorage.getItem('orderDetails')),
         orders: JSON.parse(sessionStorage.getItem('orderDetails')).orders,
         vendor_name: JSON.parse(sessionStorage.getItem('orderDetails')).vendorName,
         vendor_mobile: JSON.parse(sessionStorage.getItem('orderDetails')).vendorMobile,
         // vendor_name: JSON.parse(sessionStorage.getItem('orderDetails')).orders,
         isTimeOut: false,
         homePage: true,
         schemePage: false,
         changeVendorDetailsShow: false,
         loading: false,
         clickedOrder: false,
         ordersLoading: false,
         emailChange: JSON.parse(sessionStorage.getItem('orderDetails')).email,
         editEmail: JSON.parse(sessionStorage.getItem('orderDetails')).email,
         editableTextBox: false,
         emailTextBox: true,
         showHomeDashbaord:true,
         showCheckoutModel:false,
         showCheckStatus:false,
         pageLoader:true,
         moengageRedeemRenew_Value: "Redeem Coupon Clicked"
      };
      this.onCollaps = this.onCollaps.bind(this);
      this.currentScheme = this.currentScheme.bind(this);
      this.goBackPage = this.goBackPage.bind(this);
      this.redirectToHome = this.redirectToHome.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.enterPressed = this.enterPressed.bind(this);
      this.edit = this.edit.bind(this);
      this.canceled = this.canceled.bind(this);
      this.validatedate=this.validatedate.bind(this);
      this.redeemCoupon=this.redeemCoupon.bind(this);
      

   }

   validatedate(dateString){      
      let dateformat = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/;      
            
      // Match the date format through regular expression      
      if(dateString.match(dateformat)){      
          let operator = dateString.split('/');      
        
          // Extract the string into month, date and year      
          let datepart = [];      
          if (operator.length>1){      
              datepart = dateString.split('/');      
          }      
          let month= parseInt(datepart[0]);      
          let day = parseInt(datepart[1]);      
          let year = parseInt(datepart[2]);      
                
          // Create list of days of a month      
          let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];      
          if (month==1 || month>2){      
              if (day>ListofDays[month-1]){      
                  ///This check is for Confirming that the date is not out of its range      
                  return false;      
              }      
          }else if (month==2){      
              let leapYear = false;      
              if ( (!(year % 4) && year % 100) || !(year % 400)) {      
                  leapYear = true;      
              }      
              if ((leapYear == false) && (day>=29)){      
                  return false;      
              }else      
              if ((leapYear==true) && (day>29)){
                  return false;      
              }      
          }      
      }else{
          return false;      
      }      
      return true;      
   } 


    updateCheckStatus = (status) => {  
      this.setState({ showCheckStatus: status });  
    }; 


    moengageHomePage_Viewed()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
         window.Moengage.track_event('Home Page Viewed',
        {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") ,  Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
        }
        )
      }
    }

    moengageTabExpanded_Viewed()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
           window.Moengage.track_event('Tab Expanded',
        {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") ,  Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_number: sessionStorage.getItem("stg_clicked_OrderId")
            ,Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
         })
      }
    }

    moengageRenewPlan_Clicked()
    {
      
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
      
            window.Moengage.track_event( "Renew Plan Clicked",
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
            Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

         }
        )
      }
    }

    

    moengageRedeemCoupon_Clicked()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
            window.Moengage.track_event('Redeem Coupon Clicked',
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
            Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
            Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")


         }
        )
      }
    }

   componentDidMount() 
   {   

      ReactGA.ga('send','pageview','/home');
      sessionStorage.removeItem("stg_clicked_OrderId");
      sessionStorage.removeItem("stg_renew_clicked_order_status");
      sessionStorage.removeItem("stg_renew_clicked_order_id");
      sessionStorage.removeItem("stg_selectedPlan_Name");
      sessionStorage.removeItem("stg_selectedPlan_Duration");
      sessionStorage.removeItem("stg_selectedPlan_Price");
      sessionStorage.removeItem("stg_emailAdress_Checkout_updated");
      sessionStorage.removeItem("stg_existing_address_DeliverPage_updated");
      sessionStorage.removeItem("stg_new_address_DeliverPage_updated");
      sessionStorage.setItem("stg_Payment_Offer_Selected","default");
      sessionStorage.setItem("stg_userJourneyType","Renewal");
      this.moengageHomePage_Viewed();
      this.setState({ ordersLoading: true });
      UserService.fetchOrders(JSON.parse(sessionStorage.getItem('orderDetails')).id).then(response => {
         if (response.data !== "") {
            this.setState({ orders: response.data });
            this.setState({ pageLoader: false});
            sessionStorage.setItem("first_publication", response.data[0].scheme.sapPublication);
            
            UserService.fetchAccountInfo(this.state.userInfo.mobile).then(response => {
               this.setState({ emailChange: response.data.email });
               this.setState({ editEmail: response.data.email });
               const locationId = response?.data?.location?.id;
               if (locationId !== undefined) {
                    sessionStorage.setItem("stg_location_code", locationId)
               }
               this.setState({ ordersLoading: false });
               this.defaultCollaps();
            }).catch(err => {
               console.error(err)
         this.setState({ pageLoader: false});
            })
         }
      }).catch(err => {
         console.error(err)
         this.setState({ pageLoader: false});
      })
      setTimeout(() => {
         this.setState({ isTimeOut: true });
      }, 60000 * 60);
      this.state.orders.map((obj,i) => (obj.open = false, obj.selectedOrder = false));

  }

   handleChange(e) {
      this.setState({ editEmail: e.target.value });
   }
   enterPressed(event) {
      var code = event.keyCode || event.which;
      if (code === 13) {
         this.updateEmailAddress();
      }
   }
   edit()
   {
      this.setState({ emailTextBox: false });
      this.setState({ editableTextBox: true });
   }

   changeVendorDetails()
   {
      this.setState({ homePage: false })
      this.setState({ changeVendorDetailsShow: true })

  }


//   gotoCouponsConfirmationPage() {
//         this.setState({ changeVendorDetailsShow: false })
//         this.setState({ homePage: true })
//     }


    gotoHomePage = () =>
    {
      this.setState({ changeVendorDetailsShow: false });
      this.setState({ homePage: true })
  }

   canceled() {
      this.setState({ emailTextBox: true });
      this.setState({ editableTextBox: false });
   }
   updateEmailAddress() {
      var email = this.state.editEmail;
      if (email !== "") {
         var mobile = this.state.userInfo.mobile;
         UserService.updateEmail(email, mobile).then(response => 
            {
            if (response.data.success) {
               this.setState({ emailChange: email });
               this.setState({ editEmail: email });
               this.setState({ emailTextBox: true });
               this.setState({ editableTextBox: false });
               this.emailUpdated();
            } else {
               this.emailIdNotValid();
            }
         }).catch(err => {
            this.notFound();
         })
      } else {
         this.enterEmail();
      }


   }
   goBackPage() 
   {
      this.setState({ homePage: true });
    
      this.setState({ schemePage: false });
      this.setState({ showCheckoutModel: false });
      this.setState({ showHomeDashbaord: true });
   }




   defaultCollaps() 
   {
      let list = this.state.orders.map((obj,i) => {
      if (i === 0) 
         {
            obj.open = true;
         }
         return obj;
      });
      this.setState({ orders: list });
    
   }


   onCollaps(e) 
   {
      sessionStorage.setItem("stg_clicked_OrderId",e.target.id);
      this.moengageTabExpanded_Viewed();
   
      let id = e.target.id;
      let list = this.state.orders.map((obj,i) => {
         
         if (obj.id === id) {

            if (obj.open) {
               obj.open = false;

            } else {
               obj.open = true;
            }
         }
         return obj;
      });
      this.setState({ orders: list });
   }

    redeemCoupon(e)
    {
      // debugger
      var selectedPublicationValue=e.target.attributes.id.value
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Redeem Coupon Button from HomePage")
      sessionStorage.removeItem("homeSelectedPublicationValue")
      sessionStorage.setItem("homeSelectedPublicationValue",selectedPublicationValue)
      this.moengageRedeemCoupon_Clicked()
      window.location.href = "/redeem";
    }

   //  checkStatus()
   //  {
   //    //  window.location.href = "/redeem";
   //  }

   currentScheme(e) 
   {
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Renew Plan Button from HomePage")

      this.moengageRenewPlan_Clicked();
      let id = e.target.id;
      this.setState({ selectedOrder: true });
      let list = this.state.orders.map((obj) => {
         if (obj.id === id) {
            this.setState({ selectedOrder: true })

         }
         return obj;
      });
      this.setState({ orders: list });

      const salesOrg = e.target.id.split(" ")[0];
      const orderCode = e.target.id.split(" ")[1];
      const ordersData = e.target.id.split(" ")[2];
      const selectedPublication = e.target.id.split(" ")[3];
      const accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
      sessionStorage.setItem("selectedPublication", selectedPublication);
      sessionStorage.setItem("orderId", ordersData);
      UserService.getAllowedSchemes(salesOrg, orderCode, accountId).then(response => {

         if (response.data !== "") {
            this.setState({ homePage: false });
            sessionStorage.setItem("selectedSchemes", JSON.stringify(response.data.schemes[0]));
            this.setState({ loading: false });
            this.setState({ selectedOrder: false })

            this.setState({ schemePage: true });
            this.setState({ showCheckoutModel: true });
            this.setState({ showHomeDashbaord: false });
            ReactGA.ga('send','pageview','/renewplan')
         }
      }).catch(err => {
         this.setState({ loading: false });
         this.notFound();
      })
   }
   redirectToHome() {

      window.location.reload();
   }
   render()
   {

      const { customerName, mobile, address } = this.state.userInfo;
      printText = [];
      digitalText = [];
      if (this.state.isTimeOut) {
         sessionStorage.clear();
         alert("Session has been expired. Please login again");
         window.location.reload();

      }

      return (
         <section>
         {this.state.homePage &&
            <div id="home">
               {<LoggedInHeader />}
                  <div>
                  {this.state.pageLoader && 
                     <PageLoader/> }    
                {this.state.showHomeDashbaord &&
                  <div class="absolute-center" >
                     <div class="container">
                        <div class="row justify-content-center">                       
                           <div class="col-md-6">
                              <div class="mt-4 text-center card-box profilecard">
                                 <div class="member-card pt-2 pb-2">
                                       <div class="thumb-lg member-thumb mx-auto"><img src="https://bootdey.com/img/Content/avatar/avatar2.png" class="rounded-circle img-thumbnail" alt="profile-image" width="20%"/></div>
                                          <div class="">
                                             <div class="profile-card__txt">{customerName || 'NA'}</div>
                                             {this.state.emailTextBox && <div><h5 className="profile-card__txt">{this.state.emailChange || 'Enter your email'}
                                                <span><a className="text-reset"> <i className="ml-2 fa fa-pencil " onClick={() => this.edit()} aria-hidden="true"></i></a></span></h5>
                                             </div>
                                             }
                                             {this.state.editableTextBox &&
                                                <div className="form-inline email-box">
                                                   <input type="text"
                                                      value={this.state.editEmail || ""}
                                                      onChange={this.handleChange.bind(this)}
                                                      onKeyPress={this.enterPressed}
                                                      placeholder="Enter your email"
                                                      className="form-control form-control col-lg-7" />
                                                   <button onClick={() => this.updateEmailAddress()} className="btn btn-dark ml-lg-3 mr-2 mt-lg-0 mt-2">Update</button>
                                                   <button onClick={() => this.canceled()} className="btn btn-light mt-lg-0 mt-2">Cancel</button></div>
                                             }
                                             <div class="profile-card__txt">{mobile || 'NA'} </div>
                                             <div class="profile-card-loc">
                                                <span class="profile-card__txt"> {address.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,[,\s]*,/g, ',') || 'NA'} </span>
                                             </div>
                                             <div class="profile-card__txt">Vendor Name : {sessionStorage.getItem("stg_vendor_name")} </div>
                                             <div class="profile-card__txt">Vendor Contact : {sessionStorage.getItem("stg_vendor_mobile")} </div>
                                             <div class="profile-card__txt"><a onClick={this.changeVendorDetails.bind(this)} style={{ color: '#04A7D0', textDecoration : 'underline' }}>Change Vendor</a> </div>
                                             {/* <p><a className="blackClourV2" onClick={this.changeVendorDetails.bind(this)} className="ml-1" data-toggle="modal" data-target="#vender-change" >Change Vendor Details </a></p> */}


                                          </div>
                                    </div>
                                 </div>
                           </div>
                           <div class="col-md-8"> 
                            <h2 class="text-center blackClourV2" >Orders</h2>
                            <div id="accordion" className="accordion">
                                <div class="flex flex-column mb-5 mt-4 faq-section">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div id="accordion">
                                                <div>
                                                {
                                                      this.state.orders.map((orders, i) => 
                                                      (
                                                        <div className="col-md-12 col-12" key={i + 1}>
                                                            <div className="card br-10" key={i}>
                                                            <div> 
                                                                <h2 className="mb-0">
                                                                    {(() => 
                                                                    {

                                                                        if(orders.couponPreference !==null)
                                                                        {
                                                                           if (orders.couponPreference.includes("M")) 
                                                                           {
                                                                              couponType="Digital Coupon"

                                                                           }
                                                                           else
                                                                           {
                                                                              couponType="Physical Coupon"
                                                                           }
                                                                        }
                                                                        else
                                                                        {
                                                                            couponType=""

                                                                        }

                                                                        if (orders.status === "Terminated")
                                                                            {
                                                                               buttonHeader =
                                                                                <div class="card-header expired-order" id="heading-3">
                                                                                    <button
                                                                                     type="button" className="btn collapsed leftTextAlign" disabled={this.state.ordersLoading} data-key={orders.id} href={"#order-" + i} data-toggle="collapse" data-target={"#order-" + i}
                                                                                     aria-expanded="false"><i onClick={this.onCollaps} id={orders.id} className={orders.open ? "fa fa-minus" : "fa fa-plus"}></i> {"Terminated"} - Order {i + 1}
                                                                                        <p><span>{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                                                           <span className='dashboardCoupon'> {couponType}</span>
                                                                                        </p>
                                                                                  </button>
                                                                                </div>
                                                                            }
                                                                        else if (orders.status === "Expired")
                                                                        {
                                                                           buttonHeader = 
                                                                            <div class="card-header expired-order" id="heading-3">
                                                                                <button
                                                                                 type="button" className="btn collapsed leftTextAlign" disabled={this.state.ordersLoading} data-key={orders.id} href={"#order-" + i} data-toggle="collapse" data-target={"#order-" + i}
                                                                                 aria-expanded="false"><i onClick={this.onCollaps} id={orders.id} className={orders.open ? "fa fa-minus" : "fa fa-plus"}></i> {"Expired"} - Order {i + 1} 
                                                                                    <p><span>{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                                                       <span className='dashboardCoupon'> {couponType}</span> 
                                                                                    </p>
                                                                              </button>
                                                                            </div>
                                                                        }
                                                                        else if (orders.status === "Draft") 
                                                                        {
                                                                          
                                                                        buttonHeader = 
                                                                            <div class="card-header progress-order" id="heading-3">
                                                                                <button
                                                                                    type="button" className="btn collapsed leftTextAlign" disabled={this.state.ordersLoading} data-key={orders.id} href={"#order-" + i} data-toggle="collapse" data-target={"#order-" + i}
                                                                                    aria-expanded="false"><i onClick={this.onCollaps} id={orders.id} className={orders.open ? "fa fa-minus" : "fa fa-plus"}></i>{"In Progress"} - Order {i + 1} 
                                                                                    <p><span>{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                                                       <span className='dashboardCoupon'> {couponType}</span> 
                                                                                    </p>
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        else 
                                                                        {
                                                                           buttonHeader =
                                                                             <div class="card-header active-order" id="heading-1">
                                                                                <button
                                                                                    type="button" className="btn collapsed leftTextAlign" disabled={this.state.ordersLoading} data-key={orders.id} href={"#order-" + i} data-toggle="collapse" data-target={"#order-" + i}
                                                                                    aria-expanded="false"><i onClick={this.onCollaps} id={orders.id} className={orders.open ? "fa fa-minus" : "fa fa-plus"}></i> {"LIVE"} - Order {i + 1} 
                                                                                    <p><span>{publicationNameArr[orders.scheme.publications] ? publicationNameArr[orders.scheme.publications] : orders.scheme.publications}</span>
                                                                                       <span className='dashboardCoupon'> {couponType}</span> 
                                                                                    </p>
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    })()}
                                                                    {buttonHeader}
                                                                </h2>
                                                            </div>
                                                            <div className="orderPenal">
                                                                <div id={"order-" + i} data-key={orders.id} className={orders.open ? "panel-collapse orderPenal" : "panel-collapse panel-close orderPenal"}  aria-labelledby="headingOne" data-parent="#accordionExample" >
                                                                 
                                                                <div className="dasboardBorder" >
                                                                    <div className="account-box">
                                                                        <div className="row">
                                                                        <div className="col-md-12 mt-1">
                                                                            <ul className="ulOrder">
                                                                                <li>
                                                                                    {(() => {
                                                                                    duplicateImageRemoved = duplicateImageFilter(orders.scheme.publications);
                                                                                    var filteredText = schemeDescription(orders.scheme.publications);
                                                                                    printText = filteredText[0];
                                                                                    digitalText = filteredText[1];

                                                                                    })()}
                                                                                    {
                                                                                    duplicateImageRemoved.map(function (item, i) {
                                                                                        return <img src={item.image} alt="logo" key={i + 1} className="img-fluid mr-3 mt-2" />
                                                                                    })
                                                                                    }
                                                                                </li>
                                                                                {(() => 
                                                                                {
                                                                                    if (printText.length !== 0) 
                                                                                    {
                                                                                    return <li>
                                                                                                   
                                                                                          {JSON.stringify(printText).toString().replace(",", "").replace(/"/g, '').replace(/[.*+?^${}()|[\]\\]/g, '').replace(/,[,\s]*,/g, ',').replace(/,(?=[^,]*$)/, ' and ')}  newspaper delivered at your doorstep.

                                                                                       </li>
                                                                                    }


                                                                                })()}
                                                                                {(() => {
                                                                                    if (digitalText.length !== 0) 
                                                                                    {
                                                                                    return <li >
                                                                                                Full access to {JSON.stringify(digitalText).toString().replace(",", "").replace(/"/g, '').replace(/[.*+?^${}()|[\]\\]/g, '').replace(/,[,\s]*,/g, ',').replace(/,(?=[^,]*$)/, ' and ')}  website
                                                                                                </li>
                                                                                    }
                                                                                })()}
                                                                            <li> {Math.floor(orders.scheme.duration) || 'NA'} Months Plan </li>
                                                                            {(() => 
                                                                                 {

                                                                              if (orders.order_end_date !== null) 
                                                                              {
                                                                               return<li> {"Expiry Date : "}{Moment(orders.order_end_date).format('DD-MM-YYYY')} </li>
                                                                              }
                                                                              else
                                                                              {
                                                                                 return<li> {"Expiry Date : NA"}</li>
                                                                              }
                                                                              })()}
                                                                           <li> Coupon Number: {orders.couponNumber|| "NA"}</li>
                                                                         </ul>
                                                                        </div>
                                                                        <div className="col-md-12 ml-4">
                                                                            {(() => 
                                                                            {                           
                                                                               if (orders.status === "Draft" || orders.daysRemaining > 180 || orders.daysRemaining === null || orders.orderIdExistsInOpportunity) 
                                                                                {
                                                                                    if(orders.status === "Draft" && orders.showRedeemCouponOption===true)
                                                                                    {
                                                                                             button = 
                                                                                                <div>
                                                                                                   <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} onClick={this.redeemCoupon} className="order__button button--blue js-message-btn"> Redeem Coupon</button>
                                                                                             </div>
                                                                                    }
                                                                                    else if(orders.status === "Draft" )
                                                                                    {
                                                                                             button = 
                                                                                                <div>
                                                                                             </div>
                                                                                    }
                                                                                    else if (orders.showRedeemCouponOption===true)
                                                                                    {
                                                                                       var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                                                       sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                                                       sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                                                       
                                                                                       button = 
                                                                                          <div>
                                                                                             <button type="button" name={couponTYpe} id={orders.scheme.sapPublication} onClick={this.redeemCoupon} className="order__button button--blue js-message-btn"> Redeem Coupon</button>
                                                                                       </div>
                                                                                    } 
                                                                                    else
                                                                                    {
                                                                                       button = 
                                                                                                <div>
                                                                                             </div>
                                                                                    }
                                                                                } 
                                                                                else 
                                                                                {
                                                                                 if (orders.showRedeemCouponOption===true)
                                                                                 {
                                                                                    var couponTYpe=orders.showRedeemCouponOption+orders.couponNumber+orders.id+"p2";
                                                                                    sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                                                    sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                                                    
                                                                                    button = <div>
                                                                                        <button type="button" name={couponTYpe} onClick={this.redeemCoupon} id={orders.scheme.sapPublication} className="order__button button--blue js-message-btn"> Redeem Coupon</button>
                                                                                        <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="order__button button--orange js-message-btn">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>}  Renew Plan</button>

                                                                                    </div>
                                                                                 }
                                                                                 else
                                                                                 {
                                                                                    sessionStorage.setItem("stg_renew_clicked_order_status",orders.status);
                                                                                    sessionStorage.setItem("stg_renew_clicked_order_id",orders.id);
                                                                                 
                                                                                    button = <div>
                                                                                    <button onClick={this.currentScheme} disabled={orders.selectedOrder} id={orders.scheme.salesOrg.id + " " + orders.scheme.code + " " + orders.id + " " + orders.scheme.publications} className="order__button button--orange js-message-btn">{orders.selectedOrder && <i className="fa fa-refresh fa-spin"></i>}  Renew Plan</button>
                                                                                </div>
                                                                                    // this.moengageRenewRedeem_Clicked();
                                                                                 }
                                                                              }
                                                                            })()
                                                                            }
                                                                            {button}
                                                                        </div>
                                                                     
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div> 
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <ToastContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                           </div>
                        </div>
                     </div>
                  </div>
                  }
            </div>                            
         </div>
         }
         {this.state.schemePage && <AllSchemes style={{ "height": "100%" }} schemeCallBack={this.goBackPage} ></AllSchemes>
         }
         {this.state.changeVendorDetailsShow && <VendorDetailsChange CouponDetailConfirm={this.gotoHomePage} vendorBack={true}/>
         }
      </section>
      );
   }
}