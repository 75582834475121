import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Style.css';
import mainLogo from './../images/mainLogo.png';
export class Tnc extends Component {
    render() {
        return (
            <div>
                <section className="position-relative login-page">
		<div id="home" className="">
        <nav class="navbar navbar-expand-lg navbar-light bg-grey-mobile bg-grey">
              <a > 
                <img src={mainLogo} classNameName="img-fluid" alt="main Logo" />
              </a>
              <p className="nav-item ansp ml-auto mb-0">
                <span><u>Customer Care</u><br></br> <i className="fa fa-phone" aria-hidden="true"></i> Delhi- 01160004242 <br></br> <i className="fa fa-phone" aria-hidden="true"></i> Mumbai- 02267764242</span>
              </p>
            </nav>
			  
		</div>
	</section> 
	<div className="container t-and-c">
		<div className="row">
			<div className="col-md-12">
				 
				<div className="mt-5">
					<h4 className="text-center"><strong> UPTO Rs 250 Cash back</strong></h4>
					
					<h5 className="mt-lg-5 mt-3 mb-3 mb-lg-0">Detailed T&C:- </h5>
					
					<ul>
						<p className="mt-lg-4 mb-0"> <strong>Offer Eligibility </strong></p>
						<li>This offer is applicable only when you Pay HT Media Ltd. for ₹899 or more using Paytm wallet</li>
						<li>Offer can be availed 1 time during the campaign validity</li>
						<li>Offer is applicable for selected users only</li>

					</ul>

					
					<ul>
						<p className="mt-lg-3 mb-0"> <strong>Offer Duration</strong></p>
						<li>On your first transaction, the offer will be automatically activated.</li>
						<li>Pay HT Media Ltd. 1 time before 22-10-2020, 23:59:00 to earn upto ₹250 cashback</li>
					</ul>

					<ul>
						<p className="mt-lg-3 mb-0"> <strong>Rewards</strong></p>
						<li> Each reward will be received within 30 days of completion of the transaction on which the reward is eligible.</li>
						<li>All scratch cards issued from this offer will expire after 10 days</li>
						<li>If you have not completed your KYC, or have breached your monthly wallet limit, instead of cashback, you will receive cashback in Gift Voucher</li>
						<li>Paytm reserves its absolute right to withdraw and/or alter any terms and conditions of the offer at any time without prior notice.</li>
					</ul>
				</div>
			</div>
		</div>
   </div>
            </div>
        )
    }
}

export default Tnc
