import React, { Component } from 'react'
import LoggedInHeader from '../subComponents/LoggedInHeader';
import './../css/Checkout.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { schemeDescription, duplicateImageFilter } from '../services/schemeTextFilter';
import UserService from '../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import ShowPaytmPaymentPage from '../components/ShowPaytmPaymentPage';
import ShowRazorPayPaymentPage from '../components/ShowRazorPayPaymentPage';
import PaymentOffer from '../subComponents/PaymentOffer';
import PaymentSummary from '../subComponents/PaymentSummary';
import AcqHeader from "../subComponents/AcqHeader";
import AcqCheckout from "./Acquisition/AcqCheckout";
import Tnc from "../subComponents/Tnc";
import PopupAlert from '../subComponents/PopupAlert';
import validator from 'validator'
import ReactGA from 'react-ga';
import PopupRemoveCouponCode from '../subComponents/PopupRemoveCouponCode'



let duplicateImageRemoved;
var printText = [];
var digitalText = [];
let compoPlan =[];
export default class AllSchemes extends Component {
   defultAlert = (msg) => toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER
   });

   emailIdNotValid = () => toast.warn("Please enter valid email address", {
      position: toast.POSITION.TOP_CENTER
   });

   couponCodeBlank = () => toast.warn("Please enter coupon code", {
      position: toast.POSITION.TOP_CENTER
   });

   couponCodeInVaild = (msg) => toast.warn(msg, {
      position: toast.POSITION.TOP_CENTER
   });

   emailIdNotEntered = () => toast.warn("Please enter the email address", {
      position: toast.POSITION.TOP_CENTER
   });

   tncAlert = (msg) => toast.warn("Please read and accept the T&C check box", {
      position: toast.POSITION.TOP_CENTER
   });
   constructor(props) {
      super(props);
      this.state = {
         selectedSchems: JSON.parse(sessionStorage.getItem('selectedSchemes')),
         schemePage: true,
         checkout: false,
         loading: false,
         payforScheme: [],
         comboSchemesShow: false,
         currentPlanPublication:'',
         currentPlanDuration: '',
         currentPlanPrice: '',
         currentPlanId: '',
         paymentOption: {
                           gateway: sessionStorage.getItem('default_gateway'),
                           offerCode: null,
                           offerDescription: null
                        },
          paymentOptionGateway:sessionStorage.getItem('default_gateway'),
          paymentOptionOfferCode:null,
          paymentOptions: [],
          randomUUId: "",
          transToken: "",
          paytmPageShow: false,
          updateEmail: '',
          mobile: '',
          selectedSchemsInfo: '',
          showPaymentOffer: false,
          planNameOnSummary:'',
          showTnC:false,
          isCheckedTnc:true,
          showPopupAlertState:false,
          tncValueStatus:"",
          isEmailAdressValid:"",
          couponNotAdded:true,
          couponCodeValue:"",
          couponDiscountAmount:"",
          actualPlanPrice:"",
          removeCouponCodePopup:false,
          showPopupAlertStateRemoveCoupon:false,
          triggerSelectPlan:false,
          utmSource: null
      }

      this.goBackPage = this.goBackPage.bind(this);
      this.redirectToHome = this.redirectToHome.bind(this);
      this.getExitingPlan= this.getExitingPlan.bind(this);
      this.getCompoOtherPlan=this.getCompoOtherPlan(this)
      this.getPlanNameOnSummary=this.getPlanNameOnSummary.bind(this)
   }

   onDismissRazorpay(){
      this.setState({ paytmPageShow: false });
   }


    selectPaymentOfferL1= (value) =>
    {  
      this.setState({ paymentOption: {gateway: value[0], offerCode: value[1]}, paytmPageShow: false });
      sessionStorage.setItem("stg_Payment_Offer_Selected",value[0],"&",value[1]);
    }

    showTncModel = (status) =>
    {
         sessionStorage.setItem("stg_mo_redirectFrom","Click on T&C Link From Checkout Page")
         this.setState({ showTnC: status });
    };

    showPopUpAlert = (status) =>
    {
         sessionStorage.setItem("stg_mo_redirectFrom","Open Alert on Clicking Checkout Without selecting T&C Checkbox")
         this.setState({ showPopupAlertState: status });
    };

    showPopUpAlertRemoveCoupon = (status) =>
    {
         this.setState({ showPopupAlertStateRemoveCoupon: status });
    };

    handleTnc ()
    {
         this.setState({isCheckedTnc : !this.state.isCheckedTnc})
    }

    paymentDoneSuccess()
    {
        const { utmSource } = this.state;
        sessionStorage.setItem("stg_mo_redirectFrom","Click on Checkout Button From Checkout Page")

       this.moengageCheckoutBtn_Clicked("","","");

       let couponCodeValueforApi=this.state.couponCodeValue

       if(this.state.couponNotAdded)
       {
          couponCodeValueforApi=""
          this.setState({ couponCodeValue: "" });
       }
       if(this.state.isCheckedTnc)
       {
         //  this.setState({ popupAlert: false });
          if (this.state.paymentOption.gateway) 
          {
//               console.log("payment in ======", utmSource);
              this.setState({ loading: true });
              UserService.paymentTransaction(this.state.randomUUId, this.state.currentPlanPrice,
                this.state.mobile, this.state.currentPlanId, sessionStorage.getItem('orderId'), this.state.paymentOption.gateway,this.state.updateEmail,couponCodeValueforApi.trim(),null,utmSource).then(response =>
                  {
                     if (response.success)
                     {
                        this.setState({ transToken: response.txnToken });
                        this.setState({ mId: response.mid });
                        sessionStorage.setItem("mId",response.mid);
                        sessionStorage.setItem("stg_leadId",response.lead_id);
                        this.setState({ loading: false });
                        this.setState({ paytmPageShow: true });
                        ReactGA.ga('send','pageview','/paymentPage')
                     }
                     else
                     {
                        this.setState({ loading: false });
                        this.defultAlert(response.message);

                     }
                  }).catch(err =>
                   {
                     this.defultAlert("Failed! Check your network")
                     this.setState({ loading: false });
                })
             }
             else
             {
                this.setState({ PaymentPage: true });
                this.setState({ paytmPageShow: false });
                this.defultAlert("Please choose payment option");
             }
          }
          else
          {
               this.showPopUpAlert(true)
          }
    }

   paymentDoneSuccessForAqu()
   {
      const { utmSource } = this.state;
      let couponCodeValueforApi=this.state.couponCodeValue

      if(this.state.couponNotAdded)
      {
         couponCodeValueforApi=""
         this.setState({ couponCodeValue: "" });
      }

      sessionStorage.setItem("stg_mo_redirectFrom","Click on Checkout Button From Checkout Page")
      sessionStorage.setItem("stg_emailAdress_Checkout_updated",this.state.updateEmail);


      if(this.state.updateEmail!=="")
      {
         if (validator.isEmail(this.state.updateEmail))
         {
            this.setState({ isEmailAdressValid: true });
            this.moengageCheckoutBtn_Clicked("No",true,this.state.updateEmail);

            if(this.state.isCheckedTnc)
            {

               // this.setState({ popupAlert: false });
               if (this.state.paymentOption.gateway) 
               {
                  this.setState({ loading: true });
                     UserService.paymentTransaction(this.state.randomUUId, this.state.currentPlanPrice,
                     this.state.mobile, this.state.currentPlanId, sessionStorage.getItem('orderId'), this.state.paymentOption.gateway,this.state.updateEmail,couponCodeValueforApi.trim(),null,utmSource).then(response => {
                        if (response.success) 
                        {
                           this.setState({ transToken: response.txnToken });
                           this.setState({ mId: response.mid });
                           sessionStorage.setItem("mId",response.mid);
                           sessionStorage.setItem("stg_leadId",response.lead_id);
                           this.setState({ loading: false });
                           this.setState({ paytmPageShow: true });
                           ReactGA.ga('send','pageview','/paymentPage')
                        }
                        else
                        {
                           this.setState({ loading: false });
                           this.defultAlert(response.message)


                        }
                     }).catch(err =>
                        {

                        this.defultAlert("Failed! Check your network")
                        this.setState({ loading: false });
                     })
               }
               else
               {
                     this.setState({ PaymentPage: true });
                     this.setState({ paytmPageShow: false });
                     this.defultAlert("Please choose payment option");
               }
            }
            else
            {
               this.showPopUpAlert(true)
            }
         }
         else
         {
            this.setState({ isEmailAdressValid: false });
            this.emailIdNotValid();
            this.moengageCheckoutBtn_Clicked("Yes",false,this.state.updateEmail);
         }
      }
      else
      {
         this.emailIdNotEntered();
         this.moengageCheckoutBtn_Clicked("No","",this.state.updateEmail);
      }
   }

   genrateNumberUUId() 
   {
      const length = 15;
      const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var result = '';
      for (var i = length; i > 0; --i)
         result += chars[Math.round(Math.random() * (chars.length - 1))];
      this.setState({ randomUUId: result });
      sessionStorage.setItem("linkId", result);
   }

   
   moengageCheckoutPage_Viewed()
    {

      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {

         if(sessionStorage.getItem("createNewAccountActive")==="ON")
         {
            window.Moengage.track_event( "Acquisition Checkout Screen View",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"),
               Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
               Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }
         else
         {
            window.Moengage.track_event( "Check Out Page Viewed",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"),
               Campaign_Name: sessionStorage.getItem("stg_utm_name"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
               Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }


      }
    }

    moengageOtherPlan_selected()
    {
      

      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {

         if(sessionStorage.getItem("createNewAccountActive")==="ON")
         {
            window.Moengage.track_event( "Acquisition Other Plan Selection",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }

         else
         {
            window.Moengage.track_event( "Other Plans Selected",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"),Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
               Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }


      }
    }

    
    moengageCheckoutBtn_Clicked(isEmailEntered,emailStatus,emailValue)
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {

         if(sessionStorage.getItem("createNewAccountActive")==="ON")
         {
            window.Moengage.track_event( "Acquisition Checkout Button Clicked",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
               Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),
               Registered: sessionStorage.getItem("stg_acq_userType"),Is_Email_Entered: isEmailEntered, Email_Address: emailValue,
               Is_Email_Valid: emailStatus
            }
           )
         }
        else
         {
            window.Moengage.track_event( "Checkout Button Clicked",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
               Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),
               Registered: sessionStorage.getItem("stg_acq_userType")
            }
           )
         }
      }
    }

    moengageAddCoupon_Clicked(Is_Coupon_Entered,Is_Coupon_Valid,Coupon_Code_Value,Coupon_Type,Coupon_channel,Coupon_discount_amount,Is_CouponAdded)
    {

      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {

         if(sessionStorage.getItem("createNewAccountActive")==="ON")
         {
            window.Moengage.track_event( "Acquisition Coupon Apply Button Clicked",
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
               Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
               Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
               Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
               Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),
               Registered: sessionStorage.getItem("stg_acq_userType"),Is_Coupon_Entered: Is_Coupon_Entered, Coupon_Code_Value: Coupon_Code_Value,
               Is_Coupon_Valid: Is_Coupon_Valid,Coupon_Type:Coupon_Type,Coupon_channel:Coupon_channel,Coupon_discount_amount:Coupon_discount_amount,Is_CouponAdded:Is_CouponAdded
            }
           )
         }
           else
            {
               window.Moengage.track_event( "Renew Plan Coupon Apply Button Clicked", 
               {
                  Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
                  Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
                  Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
                  Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
                  Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),Order_Status: sessionStorage.getItem("stg_renew_clicked_order_status"),
                  Order_Number: sessionStorage.getItem("stg_renew_clicked_order_id"), Order_Expiry_Date: JSON.parse(sessionStorage.getItem('orderDetails')).orders[0].order_end_date,
                  Registered: sessionStorage.getItem("stg_acq_userType")
               }
              )
            }
      }
    }

   moengageDeleteCoupon_Clicked(Is_Coupon_Entered, Is_Coupon_Valid, Coupon_Code_Value, Coupon_discount_amount, Was_CouponAdded) {
      if (process.env.REACT_APP_ENABLE_MOENGAGE === "true") {
         if (sessionStorage.getItem("createNewAccountActive") === "ON") {
            window.Moengage.track_event("Acquisition Coupon Removed Button Clicked",
               {
                  Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Campaign_Source: sessionStorage.getItem("stg_utm_source"),
                  Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
                  Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
                  Plan_Price: sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected: sessionStorage.getItem("stg_Payment_Offer_Selected"),
                  Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number: sessionStorage.getItem("acqPhoneNumber"),
                  Registered: sessionStorage.getItem("stg_acq_userType"), Is_Coupon_Entered: Is_Coupon_Entered, Coupon_Code_Value: Coupon_Code_Value,
                  Is_Coupon_Valid: Is_Coupon_Valid, Coupon_discount_amount: Coupon_discount_amount, Was_CouponAdded: Was_CouponAdded
               }
            )
         }  else
         {
            window.Moengage.track_event( "Renew Plan Coupon Removed Button Clicked", 
            {
               Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Campaign_Source: sessionStorage.getItem("stg_utm_source"),
                  Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
                  Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
                  Plan_Price: sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected: sessionStorage.getItem("stg_Payment_Offer_Selected"),
                  Redirect_From: sessionStorage.getItem("stg_mo_redirectFrom"),
                  Registered: sessionStorage.getItem("stg_acq_userType"), Is_Coupon_Entered: Is_Coupon_Entered, Coupon_Code_Value: Coupon_Code_Value,
                  Is_Coupon_Valid: Is_Coupon_Valid, Coupon_discount_amount: Coupon_discount_amount, Was_CouponAdded: Was_CouponAdded
               }
            )
         }
      }
    }

    moengage_Acq_CheckoutBackBtn_Clicked()
    {

      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
         sessionStorage.setItem("stg_mo_redirectFrom","Back Button Clicked from Acquisition Checkout page")
         window.Moengage.track_event("Acquisition Go Back Button Clicked",
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
            Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
            Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),
            Registered: sessionStorage.getItem("stg_acq_userType")
         }
        )
      }
    }

    moengage_Acq_CheckoutCheckEmail_Clicked(isEmailEntered,emailStatus,emailValue)
    {

      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
         sessionStorage.setItem("stg_mo_redirectFrom","Back Button Clicked from Acquisition Checkout page")


        window.Moengage.track_event("Acquisition Email Address Status",
         {
            Mobile_Number: sessionStorage.getItem("stg_mobile_number"),Campaign_Source: sessionStorage.getItem("stg_utm_source"),
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Plan_Name: sessionStorage.getItem("stg_selectedPlan_Name"), Plan_Duration: sessionStorage.getItem("stg_selectedPlan_Duration"),
            Plan_Price : sessionStorage.getItem("stg_selectedPlan_Price"), Payment_Offer_Selected : sessionStorage.getItem("stg_Payment_Offer_Selected"),
            Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"), Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),
            Registered: sessionStorage.getItem("stg_acq_userType"),Email_Entered: isEmailEntered, Email_Address: emailValue,
            Email_Status: emailStatus
         }
        )
      }
    }

   componentDidMount()
   {

      if(sessionStorage.getItem("stg_loggedInHeader")==="ON")
      {
         this.setState({ updateEmail: JSON.parse(sessionStorage.getItem('orderDetails')).email });
         this.setState({ mobile: JSON.parse(sessionStorage.getItem('orderDetails')).mobile });
         this.setState({ selectedSchemsInfo: JSON.parse(sessionStorage.getItem('orderDetails'))});
      }
      else
      {
         this.setState({ mobile: sessionStorage.getItem("acqPhoneNumber") });
         sessionStorage.setItem("orderId","fresh_"+sessionStorage.getItem("acqPhoneNumber"));
         if(sessionStorage.getItem("stg_emailAdress_Checkout_updated") !==null)
         {
            this.setState({updateEmail:sessionStorage.getItem("stg_emailAdress_Checkout_updated")})
         }
      }
      if (this.state.selectedSchems.comboSchemes.length !== 0)
      {
         this.setState({ comboSchemesShow: true });
      }
      this.getExitingPlan();
      this.genrateNumberUUId();
      this.moengageCheckoutPage_Viewed();

      const url = new URL(window.location.href);
      // Get the value of a specific query parameter
      let utmSourceParam = url.searchParams.get('utm_source') ? url.searchParams.get('utm_source') : null;
      if (utmSourceParam && (utmSourceParam.toUpperCase() === 'SMS' || utmSourceParam.toUpperCase() === 'EMAIL' || utmSourceParam.toUpperCase() === 'WHATSAPP')) {
        console.log(`Value of 'paramName': ${utmSourceParam}`);
        // Do something with the query parameter value
        this.setState({ utmSource: utmSourceParam });
      }

   }

   getPlanNameOnSummary(publicationName)
   {

      if(publicationName==="HT")
      {
         this.setState({ planNameOnSummary: "Hindustan Times" });
      }
      else if(publicationName==="HH")
      {
         this.setState({ planNameOnSummary: "Hindustan" });

      }
      else if(publicationName==="BP")
      {
         this.setState({ planNameOnSummary: "Mint" });

      }
      else if(publicationName==="LH")
      {
         this.setState({ planNameOnSummary: "Live Hindustan" });

      }
      else if(publicationName==="HT;HH")
      {
         this.setState({ planNameOnSummary: "Hindustan Times:Hindustan" });

      }
      else
      {
         this.setState({ planNameOnSummary: "Hindustan Times Newspaper"});

      }
   }


   getExitingPlan()
   {
      if (this.state.selectedSchems.currentSchemes.length !== 0) 
      {
         this.state.selectedSchems.currentSchemes.map((schemes, i) => 
         (
            this.setState({ currentPlanDuration: schemes.duration }),
            this.setState({ currentPlanPrice: schemes.price }),
            this.setState({ actualPlanPrice: schemes.price }),

            this.setState({ currentPlanPublication: schemes.publications }),
            this.setState({ currentPlanId: schemes.id }),
            this.setState({ tncValueStatus: schemes.tncLink }),
            this.getPlanNameOnSummary(schemes.publications),

            sessionStorage.setItem("stg_selectedPlan_Name",schemes.publications),
            sessionStorage.setItem("stg_selectedPlan_Duration",schemes.duration),
            sessionStorage.setItem("stg_selectedPlan_Price",schemes.price)
         ))

      }
      else
      {
            this.setState({ currentPlanDuration: this.state.selectedSchems.comboSchemes[0].duration })
            this.setState({ currentPlanPrice: this.state.selectedSchems.comboSchemes[0].price })
            this.setState({ actualPlanPrice: this.state.selectedSchems.comboSchemes[0].price })
            this.setState({ currentPlanPublication: this.state.selectedSchems.comboSchemes[0].publications })
            this.setState({ currentPlanId: this.state.selectedSchems.comboSchemes[0].id })
            this.setState({ tncValueStatus: this.state.selectedSchems.comboSchemes[0].tncLink })
            this.getPlanNameOnSummary(this.state.selectedSchems.comboSchemes[0].publications)
            
            sessionStorage.setItem("stg_selectedPlan_Name",this.state.selectedSchems.comboSchemes[0].publications)
            sessionStorage.setItem("stg_selectedPlan_Duration",this.state.selectedSchems.comboSchemes[0].duration)
            sessionStorage.setItem("stg_selectedPlan_Price", this.state.selectedSchems.comboSchemes[0].price)
      }    
   }

   getCompoOtherPlan()
   {
     

      if (this.state.selectedSchems.currentSchemes.length !== 0) 
      {
         {this.state.selectedSchems.comboSchemes.map((schemes, i) => 
            {
               if(sessionStorage.getItem("createNewAccountActive")=== "ON")
               {
                  if(!schemes.publications.includes(";"))
                  {
                     compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
                  }
                  
               }
               else
               {
                  compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
               }
            })
         }
      }
      else
      {
         { this.state.selectedSchems.comboSchemes.map((schemes, i) => 
            {
               if(sessionStorage.getItem("createNewAccountActive")=== "ON")
               {
                  if(!schemes.publications.includes(";"))
                  {
                     compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
                  }
               }
               else
               {
                  compoPlan.push({'id':schemes.id, 'publications': schemes.publications, 'duration':schemes.duration,'price':schemes.price})
               }
            })

            compoPlan.shift()
         }
      }
   }

   clickOnSelect(e)
   {
      if(this.state.couponNotAdded!==true)
      {
         this.setState({ removeCouponCodePopup: true })
         sessionStorage.setItem("stg_selectPlan_info",e.target.id)
         this.setState({ showPopupAlertStateRemoveCoupon: true })
      }
      else
      {

         let newArray=[];
         sessionStorage.setItem("stg_mo_redirectFrom","Click on Select Plan Button From Checkout Page")
         if(this.state.triggerSelectPlan===true)
         {
            newArray = compoPlan.find(el => el.id === e);
         }
         else
         {
            newArray = compoPlan.find(el => el.id === e.target.id);
         }

         // this.removeCouponHandler(newArray.price,"false");
         compoPlan.push({id:this.state.currentPlanId, 'publications': this.state.currentPlanPublication, 'duration':this.state.currentPlanDuration,'price': this.state.currentPlanPrice})
         this.setState({ currentPlanDuration: newArray.duration })
         this.setState({ currentPlanPrice: newArray.price })
         this.setState({ actualPlanPrice: newArray.price })
         this.setState({ currentPlanPublication: newArray.publications })
         this.setState({ currentPlanId: newArray.id })
         this.setState({ tncValueStatus: newArray.tncLink })
         sessionStorage.setItem("stg_selectedPlan_Name",newArray.publications);
         sessionStorage.setItem("stg_selectedPlan_Duration",newArray.duration);
         sessionStorage.setItem("stg_selectedPlan_Price",newArray.price);

         if(this.state.triggerSelectPlan===true)
         {
            compoPlan.splice(compoPlan.findIndex(a => a.id === e) , 1)
         }
         else
         {
            compoPlan.splice(compoPlan.findIndex(a => a.id === e.target.id) , 1)
         }
         this.getPlanNameOnSummary(newArray.publications)
         this.moengageOtherPlan_selected();
         this.setState({ triggerSelectPlan: false })
         sessionStorage.removeItem("stg_selectPlan_info")
      }
   }


  redirectToHome() 
  {

      if (sessionStorage.getItem("createNewAccountActive")==="ON")
      {

         this.moengage_Acq_CheckoutBackBtn_Clicked()
          sessionStorage.removeItem("orderDetails")
          sessionStorage.removeItem("acqPhoneNumber")
          sessionStorage.removeItem("stg_acq_exitingUser")
          sessionStorage.removeItem("stg_acq_freshUser")

          sessionStorage.removeItem("moe_acq_selected_yearPlan")
          sessionStorage.removeItem("moe_acq_selected_planPrice")
          sessionStorage.removeItem("moe_acq_Pincode")
          sessionStorage.removeItem("stg_nda_adressDetails")
          sessionStorage.removeItem("acqAccountNotAvailable_via_Login")
          sessionStorage.removeItem("stg_salesOrg")
          sessionStorage.removeItem("stg_location_code")
          sessionStorage.removeItem("stg_city")
          sessionStorage.removeItem("stg_scheme_id")
          sessionStorage.removeItem("stg_leadId")
          sessionStorage.removeItem("stg_city")
          sessionStorage.removeItem("orderDetails")
          sessionStorage.removeItem("createNewAccountActive")

          sessionStorage.removeItem("mo_acq_numberStatus")
          sessionStorage.removeItem("stg_acq_otp")
          sessionStorage.removeItem("stg_acq_userType")

          window.location = '/newCustomer';
      }
      else
      {
         window.location = '/';
      }

  }

   goBackPage()
   {
      this.setState({ schemePage: true });
      this.setState({ checkout: false });
   }

   click = () =>
   {
      sessionStorage.setItem("stg_mo_redirectFrom","Click on Back Link From Checkout Page")
      this.props.schemeCallBack();
   }

   addCouponCodeAdjustment(amount)
   {
      var adjustedAcmount=this.state.actualPlanPrice-amount
      this.setState({ couponDiscountAmount: amount });
      this.setState({ currentPlanPrice: adjustedAcmount });
      sessionStorage.setItem("stg_selectedPlan_Price",adjustedAcmount)
   }


   clickOnDeleteCoupon = () =>
   {
      this.removeCouponHandler("","true");
   }

   popUpclickOnDeleteCoupon = (e) =>
   {
      this.removeCouponHandler("","true");
      this.setState({ triggerSelectPlan: true });
   }

   removeCouponHandler = (actualPrice,deleteClikded) =>
   {
      var adjustedAcmount
      this.setState({ couponNotAdded: true });
      if(actualPrice!=="")
      {
         adjustedAcmount=actualPrice;
      }
      else
      {
         adjustedAcmount=this.state.actualPlanPrice;
      }
      this.setState({ currentPlanPrice: adjustedAcmount });
      this.setState({ couponCodeValue: "" });
      sessionStorage.setItem("stg_selectedPlan_Price",adjustedAcmount)
      if(deleteClikded==="true")
      {
         this.moengageDeleteCoupon_Clicked("Yes","True",this.state.couponCodeValue,this.state.couponDiscountAmount,"Yes")
      }
   }

   addEmailHandler=async event =>
   {
       await this.setState({ updateEmail: event.target.value });
   }

   addCouponHandler=async event =>
   {
       await this.setState({ couponCodeValue: event.target.value });
   }

   applyCouponCode = () =>
   {

      if(this.state.couponCodeValue!=="")
      {
          let couponCodeTrim=this.state.couponCodeValue
          this.setState({ loading: true });
              UserService.verifyScratchCouponDetails(couponCodeTrim.trim(), this.state.currentPlanId,this.state.mobile).then(response =>
                  {
                     if (response.data.is_scratch_card_valid)
                     {
                        this.setState({ loading: false });
                        this.setState({ couponNotAdded: false });
                        this.addCouponCodeAdjustment(response.data.amount);
                        this.moengageAddCoupon_Clicked("Yes","True",this.state.couponCodeValue,response.data.type,response.data.discount_channel,response.data.amount,"Yes")
                     }
                     else
                     {
                        this.setState({ loading: false });
                        this.moengageAddCoupon_Clicked("Yes","False",this.state.couponCodeValue,response.data.type,response.data.discount_channel,response.data.amount,"Yes")

                        if(response.data.message==="" || response.data.message===undefined)
                        {
                           this.couponCodeInVaild("Coupon code is not valid");

                        }
                        else
                        {
                           this.couponCodeInVaild(response.data.message);
                        }

                     }
                  }).catch(err =>
                   {
                     // this.setState({ couponNotAdded: true });
                     this.defultAlert("Failed! Check your network")
                     this.setState({ loading: false });
                     this.moengageAddCoupon_Clicked("Yes","Api Issue",this.state.couponCodeValue,"","","","")
                })
      }
      else
      {
         this.couponCodeBlank();
         this.moengageAddCoupon_Clicked("No","NA",this.state.couponCodeValue,"NA","NA","NA","NO")
      }

   }


   render()
   {
      const { loading } = this.state;
      printText = [];
      digitalText = [];
      var email;
      if(this.state.updateEmail==="null" || this.state.updateEmail===null)
      {
         this.setState({ updateEmail: "" });
      }
      if(this.state.triggerSelectPlan===true)
      {
         this.clickOnSelect(sessionStorage.getItem('stg_selectPlan_info'))

      }
      return (
         <section className="">
            {this.state.schemePage &&
            <div>
               <div class="container">
                  {sessionStorage.getItem("stg_loggedInHeader")=== "ON"? (
                  <LoggedInHeader />):
                  (
                     <AcqHeader />
                  )
                  }
               </div>
               <div>
                  <div class="container mt">
                     <div class="row justify-content-center">
                        <div class="col-md-8">
                        {!sessionStorage.getItem("stg_loggedInHeader")&&
                           <AcqCheckout />
                        }
                        <div className="back-homeCheckout">
                           <p onClick={this.redirectToHome}> <a className="text-decoration-none blackClourV2"> <i className="fa fa-arrow-left ml-2"></i> Go Back</a></p>
                           </div>
                           <h2 class="text-center my-4 blackClourV2">Checkout</h2>
                           <div class="row orderCh">
                              <div class="col-md-7 blackClourV2">
                                 <div class="current-schemeCh">
                                    <div>
                                    {sessionStorage.getItem("createNewAccountActive") ==="ON"?
                                            (
                                             <h6>You are subscribing to</h6>
                                             ):
                                             (
                                             <h6>You are renewing to</h6>                                            )
                                            }
                                       {(() => 
                                       {
                                          duplicateImageRemoved = duplicateImageFilter(this.state.currentPlanPublication);
                                          var filteredText = schemeDescription(this.state.currentPlanPublication);
                                          printText = filteredText[0];
                                          digitalText = filteredText[1];
                                       })()}
                                       {
                                          duplicateImageRemoved.map(function (item, i) 
                                          {
                                             if(i==0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]
                                                      }
                                                      else if (i>0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><h5 className="planAdditionSelected">+</h5><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]

                                                      }
                                          })
                                       }
                                    </div>
                                    <div id="details-box">
                                       <p>Daily newspaper delivery </p>
                                    </div><hr></hr>
                                    <div id="duration-box">
                                    <p>{Math.floor(this.state.currentPlanDuration/12) || "NA"} Year Plan</p>
                                    </div>
                                 </div>
                                 {compoPlan.length > 0 &&
                                    <div class="other-scheme mt-4">
                                     <div class="header__center">Other Plans</div>
                                       { compoPlan.map((schemes, i) => (






                                          <div class="row mt-2">
                                             <div class="col-4 publication-details">
                                                {(() => {
                                                   duplicateImageRemoved = duplicateImageFilter(schemes.publications);
                                                   var filteredText = schemeDescription(schemes.publications);
                                                   printText = filteredText[0];
                                                   digitalText = filteredText[1];

                                                })()}
                                                {
                                                   duplicateImageRemoved.map(function (item, i) 
                                                   {
                                                      if(i==0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]
                                                      }
                                                      else if (i>0)
                                                      {
                                                         return [<div className={item.classNameCSS} key={i + 1}><h5 className="planAddition">+</h5><img  src={item.image} alt="logo" key={i} className="img-fluid mr-3 mt-2" /> </div>]

                                                      }
                                                      })
                                                }
                                                <p>{Math.floor(schemes.duration/12) || "NA"} Year Plan</p>
                                             </div>
                                             <div class="col-4 text-center price">
                                                <p style={{marginTop: "10px"}}><i class="fa fa-inr"></i>{schemes.price}</p>
                                             </div>
                                             <div class="col-4 mt-2">
                                                <button class="select-btn btn" onClick={this.clickOnSelect.bind(this)} id={schemes.id}>Select</button>
                                             </div>
                                          </div>
                                       ))}

                                 </div>
                                 }
                              </div>
                              <div className="col-md-5 summaryy-details">
                                 <PaymentSummary
                                    planNameOnSummary={this.state.planNameOnSummary}
                                    actualPlanPrice={this.state.actualPlanPrice}
                                    onSelectPaymentL1={this.selectPaymentOfferL1}
                                    couponNotAdded={this.state.couponNotAdded}
                                    couponDiscountAmount={this.state.couponDiscountAmount}

                                 />
                                 <div className="bottom-tab">
                                    <div className="total-price">
                                       <p className="text-center blackClourV2">Total (inc. GST)    :    Rs. {this.state.currentPlanPrice}</p>
                                    </div>
                                    {sessionStorage.getItem("createNewAccountActive")==="ON" &&

                                          <div className="form-group">
                                             <label for="exampleInputEmail1" className="blackClourV2">Email<span style={{color: "red"}}>*</span></label>
                                             <input type="text" value={this.state.updateEmail} onChange={this.addEmailHandler} className="form-control" id="emailInput1" aria-describedby="emailHelp" placeholder="" autoComplete="off" required/>
                                          </div>
                                      
                                    }
                                    {sessionStorage.getItem("stg_loggedInHeader")=== "ON"  && 
                                       <div>
                                          {this.state.couponNotAdded ? (
                                             <div className="coupon-section">
                                                <p>Apply Coupon</p>
                                                <div className="apply-coupon d-flex flex-wrap">
                                                   <input type="text" value={this.state.couponCodeValue} id="couponCode" name="couponCode" placeholder="Enter Coupon Code" onChange={this.addCouponHandler}/>
                                                   <button className="btn apply-btn" onClick={this.applyCouponCode}>Apply</button>
                                                </div>
                                             </div>
                                             ):
                                             (
                                                <div className="coupon-section">
                                                   <p>Apply Coupon</p>
                                                   <div className="apply-coupon pb-2 pt-2">
                                                      <p className="save">You save : <i className="fa fa-inr"></i> {this.state.couponDiscountAmount}</p>
                                                      <p className="applied">Coupon Applied : {this.state.couponCodeValue} &nbsp;<i className="fa fa-trash" style={{color: "red"}} onClick={this.clickOnDeleteCoupon}></i></p>
                                                   </div>
                                                </div>
                                             )
                                          }
                                          {this.state.removeCouponCodePopup &&
                                             <PopupRemoveCouponCode
                                                showModalPopup={this.state.showPopupAlertStateRemoveCoupon}
                                                onPopupClose={this.showPopUpAlertRemoveCoupon}
                                                removeCoupon={this.popUpclickOnDeleteCoupon}
                                             ></PopupRemoveCouponCode>
                                          }
                                          </div>
                                    }
                                       <div className="terms mt-3 blackClourV2">
                                          <input type="checkbox" name="terms" id="terms" checked={this.state.isCheckedTnc} onChange={this.handleTnc.bind(this)}/>  I Agree with
                                          <a href={this.state.tncValueStatus} className="">
                                             <u>{" Terms"} &amp; {"Conditions"}</u></a>
                                       </div>
                                    <PopupAlert
                                          showModalPopup={this.state.showPopupAlertState}
                                          onPopupClose={this.showPopUpAlert}
                                       ></PopupAlert>

                                    {sessionStorage.getItem("createNewAccountActive")=== "ON"?
                                       (<div class="checkout mt-3 text-center ee"><button class="btn checkout-btn" onClick= {this.paymentDoneSuccessForAqu.bind(this)} data-toggle="modal" data-target="#myModall">CHECKOUT</button></div>
                                       ):
                                       (<div class="checkout mt-3 text-center dd"><button class="btn checkout-btn" onClick= {this.paymentDoneSuccess.bind(this)} data-toggle="modal" data-target="#myModall">CHECKOUT</button></div>
                                       )
                                    }
                                 <ToastContainer />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>  

            </div>
            }
            {/* {this.state.showPaymentOffer && <PaymentOffer/>} */}
            {this.state.paytmPageShow && this.state.paymentOption.gateway && (this.state.paymentOption.gateway === "Paytm" ? <ShowPaytmPaymentPage txnToken={this.state.transToken} /> : 
            <ShowRazorPayPaymentPage txnToken={this.state.transToken} mobile={this.state.mobile} email={this.state.updateEmail} 
            name={this.state.selectedSchemsInfo.customerName} price={this.currentPlanPrice} onDismissRazorpay={this.onDismissRazorpay.bind(this)} />)
            }
         </section>

      );
   }
}


