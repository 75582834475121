import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";

class TermsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  isShowModal = (status) => {
    this.handleClose();
    this.setState({ showModal: status });
  };

  handleClose = () => {
    sessionStorage.setItem("stg_mo_redirectFrom", "Close the Alert on T&C");
    this.props.onPopupClose(false);
  };

  render() {
    return (
      <Fragment>
        <Modal
          show={this.props.showModalPopup}
          onHide={this.handleClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          id="model_custome_css"
        >
          <Modal.Header closeButton>
            <Modal.Title className="blackClourV2 text-center"></Modal.Title>
          </Modal.Header>
          <Modal.Body className='tandcpopup'>
            <div>
              <div class="contact_box_design">
                <div>
                  <h4>Terms & Conditions</h4>
                  <hr style={{ "border-top": "1px solid #04a7d0" }} />
                  <p>
                     <ul className="t-c-style">
                      <li>
                        This booking will be processed in the next four weeks,
                        subject to verification & payment realization. HT Media
                        Ltd. reserves the right to accept or reject any or all
                        forms received at their absolute discretion without
                        assigning any reason whatsoever.
                      </li>
                      <li>
                        Newspaper delivery under this subscription offer will be
                        made through the regular market Newspaper Vendor. We
                        advise you to confirm the terms of delivery with your
                        Vendor before enrolment.HT Media Ltd. will not be
                        responsible for any Newspaper vendor related disputes.
                      </li>
                      <li>
                        Number of Coupons as per scheme duration for verified
                        bookings post payment realization will be couriered or
                        delivered through the HT Media Ltd. representative at
                        the address mentioned in the booking form.
                      </li>
                      <li>
                        In case of revision of cover price, the difference in
                        amount will be recovered or period of booking shall be
                        adjusted accordingly.
                      </li>
                      <li>
                        The monthly coupons should be handed over to the vendor
                        only at the end of the respective month.
                      </li>
                      <li>
                        All redemption coupons should be used in sequence and
                        within number of months for scheme duration from the
                        date of booking. No coupon shall be accepted beyond 3
                        months due date of the coupon.
                      </li>
                      <li>
                        No duplicate redemption coupons will be issued in case
                        of loss or damage.
                      </li>
                      <li>
                        The coupons are non-transferable, non-exchangeable and
                        non-refundable.
                      </li>
                      <li>
                        It is agreed in case of dispute the liability of HT
                        Media Ltd. shall not exceed the amount paid as advance
                        or the extent of the value of the coupon in dispute.
                      </li>
                      <li>
                        In case of dishonour/loss/left/damage of cheque (s) HT
                        Media Ltd. reserves the right to recover the amount
                        together with costs, if any, without the delivery of the
                        copies for the Publication booked.
                      </li>
                      <li>This offer is valid for a limited period only.</li>
                      <li>
                        HT Media Ltd. reserve the right to terminate,
                        discontinue or extend this offer or any part thereof at
                        any time, at their absolute discretion without assigning
                        any reason whatsoever.
                      </li>
                      <li>
                        In case of premature termination, discontinuation or
                        rejection of offer, the subscriber shall be refunded the
                        amount on a pro rata basis.
                      </li>
                      <li>
                        This offer cannot be clubbed with any other offer.
                      </li>
                      <li>
                        Copies of respective Publication are also available
                        without this offer.
                      </li>
                      <li>
                        Mobile number is mandatory for start of the copy under
                        the scheme.
                      </li>
                      <li>
                        In case a gift is applicable on any scheme then the
                        gifts/coupons so provided to the user are at the sole
                        discretion of their respective manufacturers/owners,
                        usage and redemption shall be subject to the terms and
                        conditions provided therein. HT Media Ltd. or its group
                        companies disclaims any/all claims arising out of the
                        usage/redemption/quality/service of such gifts/coupons
                        and shall lie directly against the manufacturers/owners
                        respectively.
                      </li>
                      <li>
                        Subscriber hereby expresses that it has no objection
                        upon any call/SMS/Communication by HT Media Ltd. or any
                        third party on its behalf, communicating with Subscriber
                        with regard to the subscription. Notwithstanding
                        Subscriber's registration with NDNC (under TRAI).
                        Subscriber upon specifically opting to receive
                        communication hereby expresses his interest and accord,
                        its wilful consent to receiver communication (including
                        commercial communication) in this regard and confirms
                        that no communication related to subscription is
                        Unsolicited Commercial Communication.
                      </li>
                      <li>
                        All disputes are subject to exclusive jurisdiction of
                        Courts and Forums of Delhi only.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}

export default TermsPopup;
