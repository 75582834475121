import React, { Component } from 'react';
import mainLogo from '../images/mainLogo.png';
import ht_logo from '../images/ht_logo1.svg';
import mint_logo from '../images/mint_logo.svg';
import '../css/Style.css';
 


class LoginHeader extends Component 
{

    redirectToHome() {
        window.location.href = window.location.origin;
      
     }
    render() {
      return (
        <div className="container">
        <div className="navigation-wrap bg-light topNavigtion start-header start-style">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <nav>
                        <div className="row">
                            <div className="col-mt-4">
                            <a className="navbar-brand" target="_blank">
                                <img src={mainLogo} onClick={this.redirectToHome} alt="main Logo"/></a>    

                            </div>
                            <div className="col-mt-8">
                                <div className="NavBar">
                                <nav className="Navigation NavBarNaviation mt-2">
                                    <ul className="cm_design_logo">
                                    <li className="ht_logo">
                                        <img src={ht_logo} />
                                    </li>
                                    <li className="mint_logo">
                                        <img src={mint_logo}/>
                                    </li>
                                    </ul>
                                </nav>
                                </div>
                            </div>
                        </div>
                    </nav>
                    </div>
                </div>
            </div>
        </div>
      </div>    
            
   )
    }
  } 
  export default LoginHeader;