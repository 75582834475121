import React, { Component } from 'react'
import './../css/Style.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserService from '../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import LoggedInHeader from '../subComponents/LoggedInHeader';

var mobileState;
var name = "";
var updatedVendorCode = "";
var mobile=""
class VendorDetailsChange extends Component 
{
    msg = (str) => toast.info(str, {
        position: toast.POSITION.TOP_CENTER
    });
    constructor(props) {
        super(props)
        if (sessionStorage.getItem('vendorInfo') === "[object Object]" || sessionStorage.getItem('vendorInfo') === null) {
            mobileState= JSON.parse(sessionStorage.getItem('orderDetails')).vendorMobile;
            
        }
        else
        {
            mobileState =  JSON.parse(sessionStorage.getItem('vendorInfo')).mobile;
        }
        this.state = {
            accountInfo: JSON.parse(sessionStorage.getItem('orderDetails')),
            // vendorCode: JSON.parse(sessionStorage.getItem('orderDetails')).vendorCode,
            // vendorName: JSON.parse(sessionStorage.getItem('orderDetails')).vendorName,
            // mobile: sessionStorage.getItem('orderDetails') ,
            vendorCode: sessionStorage.getItem('stg_vendor_code'),
            vendorName: sessionStorage.getItem('stg_vendor_name'),
            mobile: sessionStorage.getItem('stg_vendor_mobile') ,
            showErrorMsg: false,
            showSaveAndCancel: false,
            vendorUpdated: false,
            vendorInfo: [],
            vendorId:''
        }
      
       
        this.handleChangeVendorName = this.handleChangeVendorName.bind(this);
        this.handleChangeVendorCode = this.handleChangeVendorCode.bind(this);
        this.handleChangeVendorMobile = this.handleChangeVendorMobile.bind(this);
        // this.handleSubmitVender = this.handleSubmitVender.bind(this);
        this.saveVendorDetails = this.saveVendorDetails.bind(this);
    }
    componentDidMount() 
    {
        // sessionStorage.setItem("stg_vendorUpdated", false);
    }
    handleChangeVendorName(event) {

        this.setState({ vendorName: event.target.value });
    }

    handleChangeVendorCode(event) {

        this.setState({ vendorCode: event.target.value });
    }

    handleChangeVendorMobile(event) 
    {
        // sessionStorage.setItem("stg_defaultMobileNumber", this.state.mobileState);
        // sessionStorage.setItem("stg_defaultVendorName", this.state.vendorName);
        // sessionStorage.setItem("stg_defaultVendorCode", this.state.vendorCode);

        // debugger
 
        this.setState({ mobile: event.target.value.replace(/\D/g, '') });
        if (event.target.value.length === 10) {
            var mobile = event.target.value;
            this.getVendorInfo(mobile,"handleChangeVendorMobile");
        }else{
         
            this.setState({ vendorCode: "" });
            this.setState({ vendorName: "" })
        }
    }
    getVendorInfo(mobileNumber,source) 
    {
        var accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
        var mobileNo = mobileNumber;
        UserService.getVenderDetails(accountId, mobileNo).then(res => {
            if (res.data.success) 
            {

                sessionStorage.removeItem('vendorInfo');
                this.setState({ vendorInfo: res.data })
                this.setState({ vendorCode: res.data.employee_code });
                this.setState({ vendorId: res.data.id });
                this.setState({ vendorName: res.data.name })
                this.setState({ mobile: res.data.mobile })
                this.setState({ showErrorMsg: false })
                if(source ==="cancel")
                {
                    this.setState({ showSaveAndCancel: false })
                }
                else
                {
                    this.setState({ showSaveAndCancel: true})

                }
  
            }
            else 
            {
                //this.msg(res.data.message);
                this.setState({ vendorCode: "NA" });
                this.setState({ vendorName: "NA" })
                name="NA"
                updatedVendorCode="NA"
                this.setState({ showErrorMsg: true })
                this.setState({ showSaveAndCancel: false })
            }

        }).catch(e => this.msg("Something went wrong"))

    }

    handleSubmitVender(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            if (this.state.mobile !== "") {

                this.getVendorInfo();
            }
        }
        // var vendorUpdate = {};
        // vendorUpdate['mobile'] = data.get("mobile");
        // vendorUpdate['vendorName'] = data.get("vendorName");
        // vendorUpdate['vendorCode'] = data.get("vendorCode");
        // sessionStorage.removeItem('vendorInfo');



    }
    click = () => 
    {
        this.props.CouponDetailConfirm();      
    }

    cancel = () => 
    {
        // this.props.CouponDetailConfirm(); 
        // debugger
        this.getVendorInfo(sessionStorage.getItem("stg_vendor_mobile"),"cancel");
        // this.setState({ showSaveAndCancel: false })
        this.setState({ vendorCode: sessionStorage.getItem("stg_vendor_code") });
        this.setState({ vendorName: sessionStorage.getItem("stg_vendor_name") })
        this.setState({ mobile: sessionStorage.getItem("stg_vendor_mobile") })
        // if(this.props.vendorBack==="true")
        // {
        //     this.props.CouponDetailConfirm();
        // }
        // else
        // {
        //     window.location.href = "/";
        // } 
    }

    handleChange(e) 
    {
      this.setState({ phoneNumber: e.target.value.replace(/\D/g, '') });
    }

    saveVendorDetails() 
    {
        if (this.state.vendorId !== "") 
        {

            var accountId = JSON.parse(sessionStorage.getItem('orderDetails')).id;
            UserService.updateVendor(this.state.vendorId,accountId).then(res => {
                if (res.data.success) 
                {
                 
                    this.setState({ showErrorMsg: false })
                    this.setState({ showSaveAndCancel: false })
                    sessionStorage.setItem("vendorInfo", JSON.stringify(this.state.vendorInfo));
                    this.setState({ vendorUpdated: true });
                    sessionStorage.setItem("stg_vendorUpdated", true);
                    sessionStorage.setItem("stg_vendor_mobile",this.state.mobile)
                    sessionStorage.setItem("stg_vendor_name",this.state.vendorName)
                    sessionStorage.setItem("stg_vendor_code",this.state.vendorCode)
                    this.msg("Vendor mobile number has been updated")
                } 
                else 
                {
                    //this.msg(res.data.message);
                    this.setState({ showErrorMsg: true })
                    this.setState({ showSaveAndCancel: false })
                }
            }).catch(e => this.msg("Something went wrong"))
        }
        sessionStorage.setItem("stg_Venodr_Post_MobileNumber",this.state.mobile)
  

    }
    render() {
        const { vendorCode, vendorMobile, vendorName, address } = this.state.accountInfo;
        if (sessionStorage.getItem('vendorInfo') === "[object Object]" || sessionStorage.getItem('vendorInfo') === null) 
        {
            name = this.state.vendorName;
            updatedVendorCode = this.state.vendorCode;
            mobile= this.state.vendorMobile;
        } 
        else 
        {
            name = JSON.parse(sessionStorage.getItem('vendorInfo')).name;
            updatedVendorCode = JSON.parse(sessionStorage.getItem('vendorInfo')).employee_code;
            mobile=  JSON.parse(sessionStorage.getItem('vendorInfo')).mobile;
        }
        if(this.state.vendorCode === "NA")
        {
            name="NA"
            updatedVendorCode="NA"
        }
        return (<section className="position-relative">
            <div id="home" className="">
                {<LoggedInHeader />}
                <div className="intro-content display-table redeem-m-coupon">
                    <div className="table-cell">
                        <div className="container">
                            <div className="row pt-5">
                                <div className="col-md-6 mx-auto col-12 pt-5">
                                    {this.props.vendorBack &&
                                    <div className="back-home">
                                        <p onClick={this.click}> <a className="blackClourV2 text-decoration-none"> <i onClick={this.click} className="fa fa-arrow-left ml-2"></i> Go Back</a></p>
                                    </div>   
                                    }
                                        <div className="">
                                        <h5 className="rmc-heading mt-2 text-center blackClourV2">Change Vendor Details</h5>
                                    </div>
                                    <div className="scheme-box mt-5 change-vendor-details">
                                        <div className="row pt-2 pb-2">
                                            <div className="col-md-12"> 
                                                <div className="form-group">
                                                    <label htmlFor="" className="">Vendor Registered Number</label>
                                                    <input className="form-control" name="mobile" value={this.state.mobile} type="tel" pattern="[0-9]*" onChange={this.handleChangeVendorMobile} />
                                                    {this.state.showErrorMsg &&
                                                        <div className="error-msg text-center">
                                                            <p className="mt-2 mb-0 error-msg red-text">Vendor not found</p>
                                                            <p className="mb-0">Drop a mail to the BPO team for redemption For vendor maping</p>
                                                            <p><strong>subscription@hindustantimes.com | 011-67764242 </strong></p>
                                                        </div>

                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="" className=""> Vendor Name</label>
                                                    <input type="text" value={this.state.vendorName} name="vendorName" onChange={this.handleChangeVendorName} className="form-control" disabled />
                                                </div>
                                            </div>
                                            <ToastContainer />

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="" className="">Vendor Code</label>
                                                    <input type="text" value={this.state.vendorCode} name="vendorCode" onChange={this.handleChangeVendorCode} className="form-control" disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.showSaveAndCancel && <div className="mt-lg-5 mt-3 text-center">
                                            <a onClick={this.cancel} className="btn btn-light save-btn mr-lg-4">Cancel</a>
                                            <a onClick={this.saveVendorDetails} className="btn btn-dark save-btn">Save</a>
                                        </div>
                                    }


                                    <div className="row footer-text">
                                        <div className="col-lg-12 mt-lg-5">
                                            <div className="text-center">
                                                <p className="mb-3 mt-3">Copyright ©  <a href="" className="text-reset text-decoration-none">HT Media</a> Limited All rights reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        )
    }
}

export default VendorDetailsChange;
