import React, { Component } from 'react';
import UserService from '../services/UserService';
import OtpPageNJ from '../components/OtpPageNJ';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../css/Style.css';
import './../css/StyleV2.css';
import './../css/animate.css';
import flag from './../images/india-flag.png';
import publication from './../images/publication.png';
import bottomNewsPaper from './../images/bottomNewsPapger.png';
import 'react-toastify/dist/ReactToastify.css';
import dotenv from 'dotenv';
import Home from './HomeNJ';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga';
import {getUtmSource} from "../common/commonFuntion"
import AuthService from '../services/AuthService';

import Carousel from "react-bootstrap/Carousel";
import banner1 from './../images/banner-1.png';
import banner2 from './../images/banner-2.png';
import banner3 from './../images/banner-3.png';
import PopupContactus from '../subComponents/PopupContactus';
// import Captcha from '../subComponents/Captcha';
// import LoginHeader from '../subComponents/LoginHeader';


dotenv.config();
let refresh=0;
const LoginHeader = React.lazy(() => import('../subComponents/LoginHeaderNew'));


ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
export default class MobileLoginNJ extends Component
{

  enterMobile = () => toast.warn("Mobile Number should not be more than 10 digits", {
    position: toast.POSITION.TOP_CENTER
 });

  constructor(props) {
    super(props);
    this.state = {
      value:'',
      phoneNumber: '',
      show: false,
      showMobileFirst: true,
      showMobile: true,
      loading: false,
      showHome:false,
      counter:30,
      delay:true,
      showPopupAlertState:false,

    };
    this.handleChange = this.handleChange.bind(this);
    this.editHanlde=this.editHanlde.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.successHome=this.successHome.bind(this);
    this.useInterval=this.useInterval.bind(this);
  }


  handleChange(e)
  {
    this.setState({ phoneNumber: e.target.value.replace(/\D/g, '') });
  }


  goBackPage() {
    this.setState({ phoneNumber: '' });
    this.setState({ showMobile: true })
    this.setState({ show: false })
  }

  successHome() 
  {
    this.setState({ show: false })
    this.setState({ showMobileFirst: false })
    this.setState({ showMobile: false })
    this.setState({ showHome: true })
    sessionStorage.removeItem("showHeader");
    sessionStorage.setItem("showHeader","ON")
  }

  editHanlde() 
  {
    this.setState({ show: false })
    this.setState({ showMobileFirst: true })
    this.setState({ phoneNumber: "" })
    sessionStorage.removeItem("stg_status");
    sessionStorage.removeItem("stg_mobile_number")
  }

  moengageHTreaders_Viewed()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      window.Moengage.track_event('HTreaders Viewed',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), 
        Campaign_Name :sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium"), 
        Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageNextButton_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      window.Moengage.add_unique_user_id("mo_htread_"+sessionStorage.getItem("stg_mobile_number"));
      window.Moengage.track_event('Before OTP Next Button Click',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"), 
        Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageAqu_NewCustomer_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on New Customer Button on HT Reader.com")
      window.Moengage.track_event('Acquisition New Customer Button Click',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")

      })
    }
  }

  moengageAqu_BuyNow_Click()
  {
    if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
    {
      getUtmSource();
      sessionStorage.setItem("stg_mo_redirectFrom","Clicked on BuyNow Button on  Carousel in login page HT Reader.com")
      window.Moengage.track_event('Acquisition BuyNow Button on  Carousel in login page',
      {
        Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
        Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom")
      })
    }
  }



  componentDidMount()
  {
    ReactGA.ga('send','pageview','/login');
    window.Moengage.destroy_session();
    AuthService.getAuth("htprint", "htprint");
    sessionStorage.removeItem("redirect_loginPage");
    if(!sessionStorage.getItem("stg_mo_htReader_count"))
    {
      sessionStorage.setItem("stg_mo_redirectFrom","HT Reader.com")
    }
    sessionStorage.removeItem('stg_isWhatsappChecked')
    sessionStorage.removeItem("acqPhoneNumber")
    sessionStorage.removeItem("stg_nda_adressDetails")
    sessionStorage.removeItem("stg_loggedInHeader")
    sessionStorage.removeItem("acqAccountNotAvailable_via_Login")
    sessionStorage.removeItem("createNewAccountActive")
    sessionStorage.removeItem("stg_acq_exitingUser")
    sessionStorage.removeItem("stg_acq_freshUser")
    sessionStorage.removeItem("stg_emailAdress_Checkout_updated")
    sessionStorage.removeItem('orderDetails')
    sessionStorage.removeItem("mo_acq_numberStatus")
    sessionStorage.removeItem("stg_acq_otp")
    sessionStorage.removeItem("stg_acq_userType")
    // if(refresh==='0')
    // {
    //   window.location.reload(true);
    // }
    // refresh++;
 
    setTimeout(() => 
    {
      this.moengageHTreaders_Viewed();
    }, 4000);

  }


  useInterval()
  {
    sessionStorage.setItem("stg_status","Registered");
    this.moengageNextButton_Click();
    clearInterval(this.Interval)
    this.setState({ counter: 30 })
    this.setState({ delay: true })
    this.Interval=setInterval(() => { 
      let timer=this.state.counter;
      if(timer > 0)
      {
        this.setState({
          counter: this.state.counter - 1
        })
      }
      else if(this.state.counter===0)
      {
        this.setState({ delay: false })
        clearInterval(this.Interval)
        
      }
    },1000)}


    

    createAccount = () =>
    {
      this.moengageAqu_NewCustomer_Click();
      ReactGA.ga('send','pageview','/NewCustomerFromLoginPage_NewCustomerBtn')

      sessionStorage.setItem("redirect_loginPage","true")
      window.location = '/?msg=newCustomer&utm_source=NewCustomerBtn';
    }

    buyNowClick=()=>
    {
      this.moengageAqu_BuyNow_Click();
      ReactGA.ga('send','pageview','/NewCustomerFromLoginPage_BuyNowBtn')

      window.location = '/?msg=newCustomer&utm_source=Banner';
    }
  

  showotp = () => 
  {
    sessionStorage.setItem("stg_status","Not Registered");
    sessionStorage.setItem("stg_mobile_number",this.state.phoneNumber);
    sessionStorage.setItem("stg_mo_redirectFrom","Click on Show OTP Next Button")

    if(this.state.phoneNumber.length >10)
    {
        this.enterMobile();
        sessionStorage.setItem("stg_status","In Vaild Number");
        this.moengageNextButton_Click();
    }
    else
    {
    if (this.state.phoneNumber) 
    {
      this.setState({ loading: true });
      var source = "SSP";
      UserService.generateOtp(this.state.phoneNumber,source).then(response => 
      {
        if (response.success && response.success === true){
          this.setState({ showMobile: true })
          this.setState({ loading: false });
          this.setState({ show: true })
          this.setState({ showMobileFirst: false })
          this.useInterval();
          ReactGA.ga('send','pageview','/otp')
          ReactGA.event({
            category: 'User',
            action: 'Click on Next Button'
          });
        }else{
          window.location.href = '/newCustomer';
          sessionStorage.setItem("acqPhoneNumber",this.state.phoneNumber)
          sessionStorage.setItem("acqAccountNotAvailable_via_Login","ON")
          this.moengageNextButton_Click();
          this.setState({ show: false });
          this.setState({ showMobileFirst: true })
          this.setState({ showMobile: true });
          this.setState({ loading: false });
        }
      }).catch(err => 
        {

          window.location.href = '/newCustomer';
          sessionStorage.setItem("acqPhoneNumber",this.state.phoneNumber)
          sessionStorage.setItem("acqAccountNotAvailable_via_Login","ON")
          this.moengageNextButton_Click();
          this.setState({ show: false });
          this.setState({ showMobileFirst: true })
          this.setState({ showMobile: true });
          this.setState({ loading: false });

        })
    } 
    else 
    {
      this.setState({ show: false });
      this.setState({ showMobile: true });
      this.setState({ loading: false });
      sessionStorage.setItem("stg_status","Invaild Number");
      this.moengageNextButton_Click();
    }
  }
  }

  enterPressed(event) 
  {
    var code = event.keyCode || event.which;
    if (code === 13 && this.state.phoneNumber.length>=10) 
    {
      this.showotp();
    }
  }

  logout()
  {
    this._logout()
  }

  _logout() {
    this.setState({
      auth: {
        ...this.state.auth,
        loggedIn: false,

      }
    })
  }

  showcontactUsPopUp = () => 
  {  
      this.setState({ showPopupAlertState: true });  
  };

  closecontactUsPopUp = ()=>{
    this.setState({ showPopupAlertState: false }); 
  }

  render() {
    const { loading } = this.state;
    return (
      <div >
          <LoginHeader/>
        {this.state.showMobile && 
          
                <div className="container">
                    <div className="row justify-content-center">
                
                    <div className="col-md-10">
                      <div>
                        <div id="demo" className="carousel slide" data-ride="carousel">
                        <ul className="carousel-indicators">
                            <li
                              data-target="#demo"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li data-target="#demo" data-slide-to="1"></li>
                        </ul>
                        <Carousel controls={false}>
                          <Carousel.Item interval={10000}>
                            <div className="carousel-item active">
                                    <img src={banner1} alt="First slide" />
                                </div>
                            </Carousel.Item>
                            <Carousel.Item interval={10000}>
                                  <div className="carousel-item active">
                                    <img src={banner3} alt="Third slide" />
                                  </div>
                                </Carousel.Item>
                          </Carousel>
                        </div>
                        <div>
                          <button onClick={this.buyNowClick} className="buy-now-cta">
                            Buy Now
                          </button>
                        </div>
                    </div>
                      </div>
                        <div className="col-md-10">   
                            <div className="row popup_style-bg mt-80">
                            <div className="col-md-12 blackClourV2 mt-4 cm_box_design">
                              <h6 className="text-center mb-4">Subscribe Now! To get print Newspaper delivery at your doorstep.</h6>
                              <div className="landing_ ">
                                <div>
                                      { this.state.showMobileFirst && 
                                        <div className="formV2">
                                        <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center'>Already an Existing Customer?</p>
                                        {/* <Captcha/> */}
                                        <div className="input-group mb-3">
                                          <div className="input-group-prepend">
                                            <span className="input-group-text"><img src={flag} className='flag fontNJ'/>&nbsp;+91</span>
                                          </div>
                                          <input type="tel" className="form-control" maxLength={10} value={this.state.phoneNumber} onChange={this.handleChange} onKeyPress={this.enterPressed} placeholder="Enter Mobile Number"/>
                                        </div>
                                        <div className='text-center'>                                      
                                            {this.state.phoneNumber.length<10? 
                                            (
                                            <button disabled={true} type="button" className="btn dislogin-btnV2 fontNJ">
                                            Next</button>
                                            ):(
                                            <button disabled={false} type="button" className="btn login-btnV2 fontNJ" onClick={() => this.showotp()}>
                                            Next</button> 
                                            )
                                          }
                                        </div>
                                        <div>
                                        </div>
                                        </div>
                                      }
                                        {this.state.show && 
                                            <div className="formV2">
                                              <p className ='blackClourV2 labelFontSize1V1 fontNJ text-center'>Enter Registered Number</p>
                                              <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text"><img src={flag} className='flag'/>&nbsp;+91</span>
                                                </div>
                                              <input type="text" className="form-control" value={this.state.value} readOnly placeholder={this.state.phoneNumber}/>
                                                <div className="input-group-append">
                                                  <span className="input-group-text edit fontNJ resnedOtp" onClick={this.editHanlde}>Edit</span>
                                                </div>
                                                </div>
                                              <OtpPageNJ otpCallBack={this.goBackPage} updateOtpTimer={this.useInterval} style={{ "height": "100%" }} mobile={this.state.phoneNumber} gotpHome={this.successHome} otpTimer={this.state.counter} timerDelay={this.state.delay} />
                                            </div>
                                          }
                                          <ToastContainer />
                                   </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <button className="clickV2 fontNJ" onClick={this.createAccount}>New Customer</button> 
                            </div>
                            <div className="bottom_contact">
                              <p data-toggle="modal" data-target="#contact_box" onClick={this.showcontactUsPopUp}><span>Contact Us</span></p>
                            </div>

                            <PopupContactus  
                                  showModalPopup={this.state.showPopupAlertState}  
                                  onPopupClose={this.closecontactUsPopUp}  
                              ></PopupContactus> 
                            {/* <img class="productImgV2 mt-4" src={bottomNewsPaper} width="50%"/>  
                            <img class="productImgV2 mt-4" src={publication} width="50%"/>     */}
                        </div>
                    </div>
                </div>
          
        }   
        {
          this.state.showHome &&  
          <Home userInfo={sessionStorage.getItem("userInfo")} />
        }

        </div>  
    );
  }
}