import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './../css/Style.css';
import UserService from '../services/UserService';
import OtpInput from 'react-otp-input';
import AllSchemes from './AllSchemes';
import Captcha from '../subComponents/Captcha';
import ReactGA from 'react-ga';
import useGAEventTracker from "../common/useGAEventTracker";


var captchaCounter=0;
let GAEventsTracker = useGAEventTracker("OTP Page");

export default class OtpPageNJ extends Component {


    constructor(props) {
        super(props);
        this.state = 
        {
            loginInfo: this.props,
            otp: '',
            userInfo: '',
            loginPage: true,
            isLoggedIn: false,
            loading: false,
            delay:true,
            wrongOtp:false,
            showCheckout:false,
            showCaptcha:false,
            showOtpSection:true,
            showIncorectOtpMsg:"Incorrect OTP*",

        };
        this.handleChange = otp => this.setState({ otp });
    }

    componentDidMount()
    {
        sessionStorage.setItem("showNextCatchaVerify","true")

    }


    resendOtp() 
    {
            var source="SSP"
            var fresh_user = true
            this.setState({ otp: "" });
            this.setState({ wrongOtp: false });

            if(this.props.otpCallType=="fresh")
            {
                UserService.generateFreshUserOtp(this.state.loginInfo.mobile, source,fresh_user).then(response =>
                    {
                        if (response.success)
                        {
                             this.props.updateOtpTimer();
                        }
                    sessionStorage.setItem("userInfo", JSON.stringify(response.data));
                })

                this.moengageAqu_ResendBtn_Click();
            }
            else
            {
                UserService.generateOtp(this.state.loginInfo.mobile, source).then(response =>
                    {
                        if (response.success)
                        {
                             this.props.updateOtpTimer();
                        }
                    sessionStorage.setItem("userInfo", JSON.stringify(response.data));
                })

            }
    }

    moengageNextSubmissionAfterOTP_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('After OTP Next Button Click',
        {
          Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
          Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Registration_Status: sessionStorage.getItem("stg_status"),
          Mobile_Number: sessionStorage.getItem("stg_mobile_number"), Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
          OTP_Status :sessionStorage.getItem("stg_mo_OTP_Status")

        })
      }
    }

    moengageAqu_ResendBtn_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('Acquisition Resend OTP Button Clicked',
        {
            Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   Mobile_Number_Status : sessionStorage.getItem("mo_acq_numberStatus"),


        })
      }
    }

    moengageAqu_NextSubmit_Click()
    {
      if(process.env.REACT_APP_ENABLE_MOENGAGE==="true")
      {
        window.Moengage.update_unique_user_id(sessionStorage.getItem("stg_mobile_number"));
        window.Moengage.track_event('Acquisition NEXT button Clicked',
        {
            Campaign_Source: sessionStorage.getItem("stg_utm_source"), Campaign_Name: sessionStorage.getItem("stg_utm_name"),
            Campaign_Medium: sessionStorage.getItem("stg_utm_medium") , Redirect_From : sessionStorage.getItem("stg_mo_redirectFrom"),
            Mobile_Number : sessionStorage.getItem("acqPhoneNumber"),   OTP_Entered : sessionStorage.getItem("stg_acq_otp"),
            Registered: sessionStorage.getItem("stg_acq_userType")
        })
      }
    }

    nextSubmit =() =>
    {
        if(this.props.otpCallType==="fresh")
        {
            this.loginFreshUser();
            this.moengageAqu_NextSubmit_Click();
            sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button on OTP Submission")
            GAEventsTracker("Next Button Click for Otp", "For Fresh User")

        }
        else
        {
            this.loginUser();
            GAEventsTracker("Next Button Click for Otp", "For Old User")
        }



    }

    loginFreshUser = () =>
    {
        sessionStorage.removeItem("stg_acq_otp")
        sessionStorage.removeItem("stg_acq_userType")
        sessionStorage.removeItem("stg_emailAdress_Checkout_updated")

        if (this.state.otp)
        {
            var mobileNum=this.state.loginInfo.mobile
            var source="SSP"
            var otp=this.state.otp
            var scheme_id=sessionStorage.getItem("stg_scheme_id")
            sessionStorage.setItem("stg_acq_otp",otp)
            this.setState({ loading: true });
            UserService.verifiyAcqFreshUserOtp(mobileNum,source,otp,scheme_id).then(response => {
                if (response !== "")
                {
                    sessionStorage.setItem("selectedSchemes", JSON.stringify(response.schemes[0]));
                    sessionStorage.setItem("default_gateway", "Razorpay")

                     if(response.addressDetails !== null)
                     {
                        sessionStorage.setItem("stg_acq_exitingUser","ON")
                        sessionStorage.setItem("orderDetails", JSON.stringify(response));
                        sessionStorage.removeItem("stg_acq_freshUser")
                        sessionStorage.setItem("stg_acq_userType","Exiting User")
                        sessionStorage.setItem("stg_emailAdress_Checkout_updated",response.email);
                    }
                    else
                    {
                        sessionStorage.setItem("stg_acq_userType","New User")
                        sessionStorage.removeItem("stg_acq_exitingUser")
                        sessionStorage.setItem("stg_acq_freshUser","ON")
                    }
                    this.props.gotCheckout();
                    ReactGA.ga('send','pageview','/getNewPlan')
             }
            }).catch(err =>
                {
                    this.setState({ loading: false });
                    this.setState({ wrongOtp: true });
            })
        }
        else
        {
            this.enterOtp();
            this.setState({ loading: false });
        }
    }


    loginUser = () => 
    {
        if (this.state.otp) 
        {
            this.setState({ loading: true });

            this.setState({ showIncorectOtpMsg: "Verifing OTP..........." });

            UserService.verifiyOtp(this.state.otp, this.state.loginInfo.mobile).then(response => {

                if (response.data !== "") 
                {
                    sessionStorage.removeItem("showHeader");
                    sessionStorage.setItem("showHeader","ON")
                    sessionStorage.setItem("stg_loggedInHeader","ON")
                    sessionStorage.setItem("orderDetails", JSON.stringify(response.data));
                    this.props.gotpHome();
                    sessionStorage.setItem("default_gateway", response.data.defaultGateway)
                    sessionStorage.setItem("stg_vendor_name",response.data.vendorName)
                    sessionStorage.setItem("stg_vendor_mobile",response.data.vendorMobile);
                    sessionStorage.setItem("stg_vendor_code",response.data.vendorCode);
                    // sessionStorage.setItem("journey", "new")
                    this.setState({ userInfo: response });
                    this.setState({ isLoggedIn: true });
                    this.setState({ loading: false });
                    this.setState({ loginPage: false });
                    sessionStorage.setItem("stg_mo_OTP_Status","Correct OTP")
                    sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button After Correct OTP")
                    this.moengageNextSubmissionAfterOTP_Click();
                    this.useInterval();
                    ReactGA.ga('send','pageview','/dashboard')
                    sessionStorage.setItem("stg_mo_OTP_Status","Correct OTP")
                    sessionStorage.setItem("stg_mo_redirectFrom","Click on Next Button After Correct OTP")
                    this.moengageNextSubmissionAfterOTP_Click();
                    // this.useInterval();
             }

            }).catch(err => 
                {
                    console.log("error status code------->",err.response)
                    this.setState({ showIncorectOtpMsg: "Incorrect OTP*" });

                    this.setState({ loading: false });
                    this.setState({ wrongOtp: true });
                    this.moengageNextSubmissionAfterOTP_Click();
                    if(err.response && err.response.status===429)
                    {

                        this.setState({ loginPage: false });

                    }
                    else
                    {
                        sessionStorage.setItem("stg_mo_OTP_Status","Wrong OTP")
                        captchaCounter++;
                        console.log("ZZ-->captchaCounter",captchaCounter)
                        if(captchaCounter>2)
                        {
                            sessionStorage.setItem("showNextCatchaVerify","false")

                            console.log("ZZ-->In-If-Logic",captchaCounter)

                            this.setState({ showCaptcha: true });
                        }

                    }

            })
        }
        else
        {
            this.enterOtp();
            this.setState({ loading: false });
            sessionStorage.setItem("stg_mo_OTP_Status","Wrong OTP")
            this.moengageNextSubmissionAfterOTP_Click();
        }
    }
    
    click = () => 
    {
        this.props.otpCallBack();
    }

    enterPressed(event) 
    {
        var code = event.keyCode || event.which;
        if (code === 13) 
        {
            this.loginUser();
        }
    }

    render() {
        const { loading } = this.state;

        return (
            <div>
                {this.state.loginPage ?
                (
                    <div>
                        <div class="promptV2 labelFontSize1V1 fontNJ row">
                            <div className="col-md-12 text-center">
                               
                                Please enter OTP*
                          
                            </div>
                            {this.state.wrongOtp && (  <div className="col-md-4 incorrectOtp1 fontNJ">{this.state.showIncorectOtpMsg}</div>)}
                        </div>
                        <div className="otpboxV2 fontNJ" >
                            <OtpInput
                                value={this.state.otp}
                                onChange={this.handleChange}
                                numInputs={4}
                                isInputNum={true}
                                shouldAutoFocus={true}
                                separator={<span > ---- </span>}
                                // onKeyPress={this.enterPressed.bind(this)}
                                autocomplete="one-time-code"
                                containerStyle={false}
                                disabledStyle={true}
                                inputStyle={{ 'width': "1.5em", 'textAlign':"center"}}
                            />
                        </div>
                        {this.state.showCaptcha &&
                            <Captcha nextBtnClock={this.nextSubmit}/>
                        }

                        <div>
                             {this.props.timerDelay && (<p className="resnedOtp fontNJ text-center">00:{this.props.otpTimer}</p>)}
                            </div>
                            <div>
                            {!this.props.timerDelay && ( <p className="text-center" onClick={() => this.resendOtp()}> <a className="resnedOtp fontNJ text-center" >Resend OTP</a></p>)}
       
                        </div>
                        <div>
                                {sessionStorage.getItem("showNextCatchaVerify")=== "true" &&
                                <div>
                                    {this.state.otp.length<4 ? (
                                        <button disabled={true} type="button" className="btn dislogin-btnV2 fontNJ">
                                        NEXT</button>
                                        ):(
                                        <button disabled={false} type="button" className="btn login-btnV2 fontNJ" onClick={() => this.nextSubmit()}>
                                        NEXT</button>
                                        )
                                    }
                                  </div>
                                }
                        </div>
                     </div>

                ):
                (
                    <div>
                        <h6 style={{color: "Red"}}>You have entered incorrect OTP multiple times. Please try after 1 hour.</h6>
                    </div>
                )
                }
                {this.state.showCheckout &&
                <div>
                    <AllSchemes/>
                </div>
            }
            </div>
        );
    }
}