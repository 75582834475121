import React, { Component, Fragment } from 'react';  
import { Modal } from 'react-bootstrap';  
import './css/sso.css';

class LoginSSO extends Component {  
    constructor(props) {  
        super(props);  
        this.state = {  
        };  
    } 
    
   componentDidMount() 
   {
    this.writeCookie();
   }

   writeCookie=()=>{


        try{
            var generatedToken = this.urlParam("token");
            var name = this.urlParam("name");
            var clientId = this.urlParam("clientId");
            if(generatedToken!=0 && name!=0){
                this.writeCookieEvents(generatedToken, clientId, name);
            }
        }
        catch(e){
            var generatedToken = this.urlParam("token");
            var name = this.urlParam("name");
            var clientId = this.urlParam("clientId");
            if(generatedToken!=0 && name!=0){
                this.writeCookieEvents(generatedToken, clientId, name);
            }else{
                var nextDomain = "https://accounts.hindustantimes.com/login_successful";
                this.redirectUSER(nextDomain);
            }

        }
    }

    setCookieFromBackEnd=(generatedToken, name, clientId)=>
    {
        this.redirectUSER(this.redirectURL(generatedToken, name, clientId));
    }

    writeCookieEvents=(generatedToken, clientId, name)=>{
        var date = new Date();
        var date2 = new Date();
        date.setTime(date.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
        date2.setTime(date2.getTime() + (10 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
        var domain = this.thisDomainCookeiName();

        var expires5Mins = "; expires=" + date2.toGMTString();

        document.cookie = "token=" + generatedToken + expires + domain + "; SameSite=None; Secure";
        document.cookie = "_ht_clientid=" + clientId + expires + domain + "; SameSite=None; Secure";
        document.cookie = "name=" + name + expires + domain + "; SameSite=None; Secure";

        if(this.getCookie('token')) {

            try{
                this.setCookieFromBackEnd(generatedToken, name, clientId);
            }
            catch(e){
                this.redirectUSER(this.redirectURL(generatedToken, name, clientId));
            }
        }
        else{
            document.cookie = "token=" + generatedToken + expires + domain + "; SameSite=None; Secure";
            document.cookie = "_ht_clientid=" + clientId + expires + domain + "; SameSite=None; Secure";
            document.cookie = "name=" + name + expires + domain + "; SameSite=None; Secure";
            try{
                this.setCookieFromBackEnd(generatedToken, name, clientId);
            }
            catch(e){
                this.redirectUSER(this.redirectURL(generatedToken, name, clientId));
            }
        }

    }


    redirectURL=(token, name , clientId)=>{
        var domainName = this.redictectUSERtoNext();
        if(domainName.includes("accounts.hindustantimes.com")){
            return domainName;
        }else{
            return domainName+"login.html?token="+token+"&name="+name+"&clientId="+clientId;
        }
    }

    redirectUSER=(referralURL)=>{
        try{
            window.location.href = referralURL;
        }
        catch(e){
            window.location.href = referralURL;
        }
    }

    redictectUSERtoNext=()=>{
        var thisURL = window.location.href;
        var nextDomain = "https://accounts.hindustantimes.com/login_successful";
        var thisDomain = this.extractHostname(thisURL);
        if(!this.isEmpty(window.ssoDomainList)){
            for(var i = 0; i <window.ssoDomainList.length; i++){
                if(window.ssoDomainList[i].includes(thisDomain)){
                    if(i+1 >=window.ssoDomainList.length){
                        break;
                    }else{
                        var nextDomain = window.ssoDomainList[i+1];
                        break;
                    }
                }
            }
        }
        return nextDomain;
    }

    thisDomainCookeiName=()=>{
        return "; domain=ht-subscription-staging.herokuapp.com;path=/";
    }

    isEmpty=(obj)=>{
        if(typeof obj !="undefined" && typeof obj!=null && obj!=""){
            return false;
        }
        else{
            return true;
        }
    }
    
    urlParam=(name)=> {
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(name)) {
            return urlParams.get(name);
        } else {
            return 0;
        }
    }

    getCookie=(name)=> {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    extractHostname=(url) =>{
        var hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        return hostname;
    }

  
    render() 
    {  
        return (  
            <div>
                <div style={{ text: 'center', marginTop:'20%' }}>
        	        <div class="loader"></div>
                </div>
            </div>
        );  
    }  
}  
  
export default (LoginSSO); 