import React, { Component } from 'react';
import mainLogo from '../images/mainLogo.png';
import '../css/Style.css';
 


class LoginHeader extends Component 
{

    redirectToHome() {
        window.location.href = '/newjourney';
      
     }
    render() {
      return (
            <div class="navigation-wrap bg-light topNavigtion start-header start-style">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <nav class="navbar navbar-expand-md navbar-light">
                        
                            <a class="navbar-brand" target="_blank"><img src={mainLogo} onClick={this.redirectToHome} alt="main Logo"/></a>    
                            
                           
                            <div className="ml-auto">
                                 <ul class="navbar-nav ml-auto py-4 py-md-0 blackClourV2">
                                   <p className="nav-item ansp ml-auto mb-0 callus">
                                        <span className="blackClourV2">
                                            <div className=" blackClourV2 fontNJ">
                                            <i className=" fa fa-phone blackClourV2" aria-hidden="true"></i>
                                            &nbsp; Call Us </div>
                                            <div className=" blackClourV2 fontNJ" aria-hidden="true"> Delhi 01160004242 <br></br> 
                                             Mumbai 02267764242 </div>
                                            </span>
                                     </p>
                                </ul>

                            </div>
                            
                        </nav>      
                    </div>
                </div>
            </div>
        </div>       
            
   )
    }
  } 
  export default LoginHeader;